import {MutableRefObject, useEffect, useRef} from "react";
import JSZip from "jszip";
import PQueue from "p-queue";
import {saveAs} from 'file-saver';


function checkFloat(x: any) {
    // check if the passed value is a number
    if (typeof x == 'number' && !isNaN(x)) {

        // check if it is integer
        return x % 1 !== 0;

    } else {
        return false;
    }
}

function sortObj(obj: { [id: string]: any }, comparator: (a: string, b: string) => number, reverse: boolean) {
    let coef = 1;
    if (reverse) {
        coef = -1;
    }
    return Object.keys(obj).sort((a, b) => coef * comparator(a, b)).reduce(function (result: {
        [id: string]: any
    }, key) {
        result[key] = obj[key];
        return result;
    }, {});
}

function compareRunIds(a: string, b: string): number {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const arrA = a.split("_");
    const arrB = b.split("_");

    if (arrA.length !== arrB.length) {
        if (arrA.length > arrB.length) {
            return -Math.pow(10, 5);
        } else {
            return Math.pow(10, 5);
        }
    }

    let baseIdx = 0;
    if (arrA.length === 6) {
        baseIdx = 1;
    }

    let first: number;
    let second: number;

    if (arrA[baseIdx + 2] !== arrB[baseIdx + 2]) {
        first = +arrA[baseIdx + 2];
        second = +arrB[baseIdx + 2];
        return (first - second) * Math.pow(10, 4);
    }

    if (months.indexOf(arrA[baseIdx]) !== months.indexOf(arrB[baseIdx])) {
        return (months.indexOf(arrA[baseIdx]) - months.indexOf(arrB[baseIdx])) * Math.pow(10, 3);
    }

    if (arrA[baseIdx + 1] !== arrB[baseIdx + 1]) {
        first = +arrA[baseIdx + 1];
        second = +arrB[baseIdx + 1];
        return (first - second) * Math.pow(10, 2);
    }

    if (arrA[baseIdx + 3] !== arrB[baseIdx + 3]) {
        first = +arrA[baseIdx + 3];
        second = +arrB[baseIdx + 3];
        return (first - second) * Math.pow(10, 1);
    }

    first = +arrA[baseIdx + 4];
    second = +arrB[baseIdx + 4];
    return (first - second);
}

function hash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function strToColor(str: string) {
    let h = hash(str);
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (h >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

function useInterval(callback: () => void, delay: number) {
    const savedCallback: MutableRefObject<any> = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function generateGuid() {
    return Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
}

const handleDownload = async (urls: Array<string>, filenames: Array<string>, zipName: string, callWhenDone: () => void) => {
    if (urls.length === 0) {
        return;
    }

    if (zipName === null) {
        zipName = "content.zip";
    }

    let progress = document.getElementById(zipName.split(".")[0] + "_progress");

    let count = 0;
    let zip = new JSZip();
    let JSZipUtils = require('jszip-utils');

    // Create a queue with a concurrency of 10
    const queue = new PQueue({concurrency: 4});

    urls.forEach((url, index) => {
        let fileName: string;
        if (filenames === null) {
            let tokens = url.split("/");
            fileName = tokens[tokens.length - 1];
        } else {
            fileName = filenames[index]
        }

        // Add a task to the queue
        queue.add(() => new Promise<void>((resolve, reject) => {
            JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
                if (err) {
                    reject(err);
                } else {
                    zip.file(fileName, data, {binary: true});
                    count++;
                    if (progress !== null) {
                        progress.children[1].innerHTML = count + "/" + urls.length + " files ready";
                    }
                    resolve();
                }
            });
        }));
    });

    // Wait for all tasks in the queue to finish
    await queue.onIdle();

    zip.generateAsync({type: "blob"}).then(function (content) {
        saveAs(content, zipName);
    }).then(function () {
        if (progress !== null) {
            progress.innerHTML = "Ready!";
        }
        if (callWhenDone !== null) {
            callWhenDone();
        }
    });

    return true;
}

function padNumber(num: number | string, size: number) {
    if (typeof num === "number") {
        num = num.toString();
    }
    while (num.length < size) num = "0" + num;
    return num;
}

function shortRunId(runId: string) {
    let tokens = runId.split("_");
    if (tokens.length === 5) {
        return runId.replaceAll("_", " ")
    }
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month = months.indexOf(tokens[1]) + 1;

    return tokens[0] + " " + padNumber(tokens[2], 2) + "." + padNumber(month, 2) + "." + tokens[3].substring(2, 4) + " " + tokens[4] + ":" + tokens[5];
}

export {
    shortRunId,
    padNumber,
    generateGuid,
    hash,
    compareRunIds,
    sortObj,
    strToColor,
    useInterval,
    checkFloat,
    handleDownload
};