import React, {useState} from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography
} from "@mui/material";


export default function TextDialog(props: { desc: string, open: boolean, onClose: (options: Array<string> | null) => void, options: Array<string> }) {
    const [pickedOptions, changePickedOptions] = useState<Array<string>>([]);
    const renderItem = (name: string, value: boolean) => {
        return (
            <FormControlLabel
                key={name}
                control={
                    <Checkbox
                        checked={value}
                        id={name}
                        onChange={() => value ? changePickedOptions(pickedOptions.filter((v) => v !== name)) : changePickedOptions([...pickedOptions, name])}
                    />
                }
                label={
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1">{name}</Typography>
                    </Box>
                }
            />
        );
    }

    return (
        <Dialog open={props.open} onClose={() => props.onClose(null)}>
            <DialogTitle>Pick from options</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.desc}
                </DialogContentText>
                <FormGroup>
                    <Grid container spacing={1}>
                        {props.options.map(name => (
                            <Grid item xs={6}
                                  key={name}>{renderItem(name, (pickedOptions.indexOf(name) !== -1))}</Grid>))}
                    </Grid>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(null)}>Cancel</Button>
                <Button onClick={() => props.onClose(pickedOptions)}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}