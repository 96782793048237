import * as React from "react";

import CheckboxGroup from "../CheckboxGroup"

import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";


interface KPITableTabProps {
    properties: Array<string>,

    enabled: Array<string>,
    runs: { [runId: string]: { [indicator: string]: number } },

    onPropertiesChange(s: string): void,
}

export default function KPITableTab(props: KPITableTabProps) {
    const enabled = ["RunId", ...props.enabled]

    const renderHeader = (headers: Array<string>) => {
        return (
            <TableHead>
                <TableRow>
                    {headers.map((label, idx) => {
                        if (idx === 0) {
                            return (<TableCell key={label}>{label}</TableCell>);
                        }
                        return (<TableCell key={label} align="right">{label}</TableCell>);
                    })}
                </TableRow>
            </TableHead>);
    }

    const renderRow = (run: any, enabled: Array<string>) => {
        return (
            <TableRow
                key={run["RunId"]}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                {enabled.map(name => {
                    if (name === "RunId") {
                        return (
                            <TableCell component="th" scope="row" key={name}>
                                <Typography variant="body1" component="div">{run[name]}</Typography>
                            </TableCell>);
                    } else {
                        let value = run[name];
                        if (!isNaN(value) && value.indexOf('.') !== -1) {
                            value = parseFloat(value).toFixed(4);
                        }
                        return (<TableCell align="right" key={name}>{value}</TableCell>);
                    }
                })}
            </TableRow>
        );
    }

    return (
        <Stack sx={{alignItems: "center", justifyContent: "center"}}>
            <CheckboxGroup all={props.properties} enabled={props.enabled} onChange={props.onPropertiesChange}/>
            <TableContainer component={Paper} sx={{maxHeight: 900, margin: 2, width: "98%"}}>
                <Table stickyHeader sx={{minWidth: 650, overflow: 'auto'}} aria-label="runs table">
                    {renderHeader(enabled)}
                    <TableBody>
                        {Object.keys(props.runs).map((run) => renderRow({"RunId": run, ...props.runs[run]}, enabled))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>);
}