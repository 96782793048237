import * as React from "react";
import {useEffect} from "react";

import {auth} from '../utils/firebase'

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {onAuthStateChanged} from "firebase/auth"

import {Box, CircularProgress, Typography} from '@mui/material';
import Header from '../components/Header'
import logo from "../images/logo.png"


export default function LoginPage(props: { name: string, redirect: string }) {

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                window.location.replace(props.redirect);
            }
        });

        const ui = new firebaseui.auth.AuthUI(auth);
        const uiConfig = {
            callbacks: {
                signInFailure: (error: any) => (console.log(error)),
                uiShown: function () {
                    document.getElementById('loader')!.style.display = 'none';
                }
            },
            signInSuccessUrl: props.redirect,
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false
                },
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.GithubAuthProvider.PROVIDER_ID,
            ],
        };
        ui.start('#firebaseui-auth-container', uiConfig);

    }, [props.redirect]);

    return (
        <Box>
            <Header name={props.name} icon={logo}/>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography
                    align="center"
                    variant="h3"
                    component="div"
                    sx={{flexGrow: 1, margin: 8}}>
                    Welcome to {props.name}
                </Typography>
                <div id="firebaseui-auth-container"></div>
                <CircularProgress id="loader"/>
            </Box>
        </Box>
    );
}
