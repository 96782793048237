import * as React from "react";

import {Box, Chip, Stack, Typography} from '@mui/material';
import {ResponsiveCalendar} from '@nivo/calendar'
import RunAutocomplete from "../RunAutocomplete";


interface KPIControlTabProps {
    apiUrl: string,
    calendarData: { from: string, to: string, frequency: Array<{ day: string, value: number }> } | undefined,

    runs: Array<string>,

    onRemove(runs: Array<string>): void,

    onAdd(runs: Array<string>): void
}

export default function KPIControlTab(props: KPIControlTabProps) {

    const selectDay = (day: { data: any, date: Date }) => {
        if ('data' in day) {
            const startDate = day.date;
            const daySpan = 60 * 60 * 24 * 1000;
            const endDate = new Date(startDate.getTime() + daySpan);
            const query = props.apiUrl + "/serialized/search?extensions=csv&dataTypes=final&from=" + startDate.toISOString() + "&to=" + endDate.toISOString();
            fetch(query, {method: "GET"})
                .then(response => {
                    if (!response.ok) {
                        throw new Error("HTTP error " + response.status);
                    }
                    return response.json();
                })
                .then((json: Array<any>) => {
                    if (json.length > 0) {
                        props.onAdd(json.map(r => r.metadata.runId));
                    }
                });
        }
    }

    return (
        <Box sx={{alignItems: "center", justifyContent: "center"}}>
            <Stack direction="column" spacing={5} sx={{alignItems: "center", justifyContent: "center"}}>
                <RunAutocomplete apiUrl={props.apiUrl} onAdd={(str: string) => props.onAdd([str])}/>
                <Box sx={{
                    height: "150px",
                    width: "80%",
                    border: "0.5px solid gray",
                    borderRadius: 1,
                    overflow: 'auto'
                }}>
                    {
                        props.runs.length > 0 &&
                        props.runs.map((run) =>
                            <Chip
                                sx={{margin: 1, fontSize: 13}}
                                label={run}
                                onDelete={() => props.onRemove([run])}
                                key={run}/>)}
                    {props.runs.length < 1 &&
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%"
                        }}>
                            <Typography variant="body1" color="text.secondary">
                                Chosen runs will be displayed here...
                            </Typography>
                        </Box>
                    }
                </Box>
                <Typography variant="h5" color="text.primary">
                    Choose a day:
                </Typography>
                {props.calendarData !== undefined &&
                    <Box sx={{height: "350px", width: "100%"}}>
                        <ResponsiveCalendar
                            data={props.calendarData.frequency}
                            from={props.calendarData.from}
                            to={props.calendarData.to}
                            emptyColor="#eeeeee"
                            colors={['#97e3d5', '#61cdbb', '#e8c1a0', '#f47560']}
                            margin={{top: 40, right: 40, bottom: 40, left: 40}}
                            yearSpacing={40}
                            monthBorderColor="#ffffff"
                            dayBorderWidth={2}
                            dayBorderColor="#ffffff"
                            onClick={(d: any, e: any) => selectDay(d)}
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'row',
                                    itemCount: 4,
                                    itemWidth: 42,
                                    itemHeight: 36,
                                    itemsSpacing: 14,
                                    itemDirection: 'right-to-left'
                                }
                            ]}
                        /></Box>
                }
            </Stack>
        </Box>
    );
}