import * as React from "react";
import {useEffect, useState} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../utils/firebase";
import UnsignedLayout from "../components/UnsignedLayout";
import SignedHeader from "../components/SignedHeader";
import logo from "../images/logo.png";
import {Box, Drawer, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import SideDrawer from "../components/SideDrawer";
import TagsTab from "../components/ml/TagsTab";
import TrainingTab from "../components/ml/TrainingTab";

export default function MLPage(props: { name: string, apiUrl: string }) {
    const [drawerOpened, openDrawer] = useState(false);
    const [tab, changeTab] = useState("2");
    const [userName, changeUserName] = useState("");

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user && auth.currentUser) {
                let name = auth.currentUser.displayName;
                if (name === undefined || name === null) {
                    name = auth.currentUser.email!.split("@")[0];
                }
                changeUserName(name);
            } else {
                window.location.replace('/');
            }
        });
    }, []);

    return userName === "" ? <UnsignedLayout/> : (
        <Box sx={{display: "flex", flexFlow: "column", height: "100%"}}>
            <SignedHeader
                name={props.name}
                icon={logo}
                openDrawer={() => openDrawer(true)}/>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={tab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(e: any, v: string) => changeTab(v)} aria-label="ml training tabs"
                                 variant="fullWidth">
                            <Tab label="Tags" value="1"/>
                            <Tab label="Training" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <TagsTab apiUrl={props.apiUrl}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <TrainingTab apiUrl={props.apiUrl}/>
                    </TabPanel>
                </TabContext>
            </Box>

            <Drawer
                anchor="left"
                open={drawerOpened}
                onClose={() => openDrawer(false)}
            >
                <SideDrawer
                    closeDrawer={() => openDrawer(false)}
                    user={userName}
                />
            </Drawer>
        </Box>
    )
}
