import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";

export default function EndpointList(props: {
  chosenRobot: undefined | string;
  notifyChange(endpoint: { name: string; address: string; desc: string }): void;
}) {
  const [endpoint, changeEndpoint] = useState("Localhost");

  const endpoints: Array<{ name: string; address: string; desc: string }> =
    useMemo(
      () => [
        {
          name: "Localhost",
          address: "wss://127.0.0.1:9090",
          desc: "Server running on a computer you are using now.",
        },
        {
          name: "Fenris",
          address: "wss://192.168.8.105:9090",
          desc: "Machine with its own local network, Beckhoff controller and camera over TCP.",
        },
        {
          name: "Loke",
          address: "wss://loke.site-tech.dk:9090",
          desc: "Machine without its own local network, Arduino controller and RealSense camera.",
        },
        {
          name: "Sleipner",
          address: "wss://sleipner.site-tech.dk",
          desc: "Third generation of the machine. Fenris design with minor improvements.",
        },
        {
          name: "Miro",
          address: "ws://192.168.8.151:9090",
          desc: "Ubuntu computer for testing",
        },
      ],
      []
    );

  useEffect(() => {
    let chosenRobot = "Localhost";
    if (props.chosenRobot) {
      chosenRobot =
        props.chosenRobot.charAt(0).toUpperCase() + props.chosenRobot.slice(1);
    }
    const filtered = endpoints.filter((o) => o.name === chosenRobot);
    if (filtered.length > 0) {
      changeEndpoint(chosenRobot);
      props.notifyChange(filtered[0]);
    }
  }, [endpoints, props.chosenRobot, props]);

  const handleChange = (v: any) => {
    window.location.replace("/robots/" + v.props.value.toLowerCase());
  };

  const renderEndpoint = (e: {
    name: string;
    address: string;
    desc: string;
  }) => {

    return (
      <MenuItem key={e.name} value={e.name}>
        <Stack direction="column">
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Typography
              component="div"
              variant="body1"
              sx={{ 
                fontWeight: (e.name === endpoint ? "bold" : "none"),
                textDecoration: (e.name === endpoint ? "underline" : "none"), 
                fontSize: "12px"
              }}
            >
              {e.name}
            </Typography>
            <Typography component="div" variant="caption"
              sx = {{
                fontSize: "9px"
              }}
            >
              ({e.address.split("://")[1]})
            </Typography>
          </Stack>
          <Typography
            sx={{
              maxWidth: "100%",
              height: "auto",
              whiteSpace: "normal",
              fontSize: "10px"
            }}
            component="p"
            variant="caption"
            noWrap={false}
          >
            {e.desc}
          </Typography>
        </Stack>
      </MenuItem>
    );
  };

  return (
    <FormControl
      sx={{
        width: "100%",
        margin: "4px",
        maxHeight: "7%",
      }}
    >
      <InputLabel id="endpoint-select-label">Endpoint</InputLabel>
      <Select
        labelId="endpoint-select-label"
        id="endpoint-select"
        value={
          endpoints.filter((o) => o.name === endpoint).map((o) => o.name)[0]
        }
        label="Endpoint"
        onChange={(e, v) => handleChange(v)}
        sx={{ maxHeight: "100%" }}
      >
        {endpoints.map((e) => renderEndpoint(e))}
      </Select>
    </FormControl>
  );
}
