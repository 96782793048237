import {
    Alert,
    Box,
    Checkbox,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import RunAutocomplete from "../RunAutocomplete";
import RunTrainingButton from "./RunTrainingButton";
import React, {useEffect} from "react";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

export interface LabelingConfig {
    labelingModel: string | null,
    useApi: boolean,
    minAnnotationRatio: number,
    maxUncertainty: number,
}

export interface trainConfig {
    noDepth: boolean,
    initFilters: number
    architecture: string,
    learningRate: number,
    weightDecay: number,
    maxEpochs: number,
}

export interface MLConfig {
    modelName: string | null,
    redoFiles: boolean,
    redoLabels: boolean,
    destroyAfter: boolean,
    runs: Array<string>,
    tags: Array<string>,
    labelingConfig: LabelingConfig,
    trainConfig: trainConfig,
}

export default function MLConfigForm(props: { apiUrl: string, tags: Array<string>, models: Array<string> }) {

    const [openAlert, changeOpenAlert] = React.useState<"success" | "not" | "missing" | null>(null);

    const getDefaultConfig = () => {
        let labelingModel = null;
        if (props.models.length > 0) {
            labelingModel = props.models[0];
        }

        return {
            "modelName": null,
            "redoFiles": false,
            "redoLabels": false,
            "destroyAfter": true,
            "runs": [],
            "tags": [],
            "labelingConfig": {
                "labelingModel": labelingModel,
                "useApi": true,
                "minAnnotationRatio": 0.0,
                "maxUncertainty": 0.2,
            },
            "trainConfig": {
                "noDepth": false,
                "initFilters": 8,
                "architecture": "l4",
                "learningRate": 0.001,
                "weightDecay": 0.0002,
                "maxEpochs": 10,
            }
        };
    }

    useEffect(() => {
        changeConfig(getDefaultConfig());
    }, [props.models]);

    const [config, changeConfig] = React.useState<MLConfig>(getDefaultConfig());
    const [dataTag, changeDataTag] = React.useState<boolean>(true);

    const handleClose = () => {
        changeOpenAlert(null);
    };

    function getCurrentDateTime() {
        const date = new Date();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${month}-${day}_${hour}-${minutes}`;
    }

    const renderTagsChoice = () => {
        return (<Stack
            direction="column"
            sx={{width: "100%"}}
            spacing={1}
            alignItems="center"
        >
            <FormControl sx={{width: "80%"}}>
                <InputLabel id="tags-checkbox-label">Tags</InputLabel>
                <Select
                    labelId="tags-checkbox-label"
                    id="tags-multiple-checkbox"
                    multiple
                    value={config.tags}
                    onChange={(event) => changeConfig({
                        ...config,
                        tags: (typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
                    })}
                    input={<OutlinedInput label="Tag"/>}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {props.tags.map((tag) => (
                        <MenuItem key={tag} value={tag}>
                            <Checkbox checked={config.tags.indexOf(tag) > -1}/>
                            <ListItemText primary={tag}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>);
    }

    const renderRunsChoice = () => {
        return (<Stack
            direction="column"
            sx={{width: "100%"}}
            spacing={1}
            alignItems="center"
        >
            <RunAutocomplete apiUrl={props.apiUrl} onAdd={(e) => {
                if (!config.runs.includes(e)) {
                    changeConfig({...config, runs: [...config.runs, e]})
                }
            }}/>
            <Box sx={{
                height: "150px",
                width: "100%",
                border: "0.5px solid gray",
                borderRadius: 1,
                overflow: 'auto'
            }}>
                {
                    config.runs.length > 0 &&
                    config.runs.map((run) =>
                        <Chip
                            sx={{margin: 1, fontSize: 13}}
                            label={run}
                            onDelete={() => changeConfig({...config, runs: config.runs.filter((r) => r !== run)})}
                            key={run}/>)}
                {config.runs.length < 1 &&
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%"
                    }}>
                        <Typography variant="body1" color="text.secondary">
                            Chosen runs will be displayed here...
                        </Typography>
                    </Box>
                }
            </Box>
        </Stack>);
    }

    let snackbarContent: ReactJSXElement | undefined = undefined;
    if (openAlert === "success") {
        snackbarContent = (<Alert variant="filled" onClose={handleClose} severity="success" sx={{width: '100%'}}>
            Training started, wait please
        </Alert>);
    } else if (openAlert === "not") {
        snackbarContent = (<Alert variant="filled" onClose={handleClose} severity="error" sx={{width: '100%'}}>
            Error starting training
        </Alert>);
    } else if (openAlert === "missing") {
        snackbarContent = (<Alert variant="filled" onClose={handleClose} severity="warning" sx={{width: '100%'}}>
            No labeling model
        </Alert>);
    }

    return (
        <Grid container sx={{width: "100%"}} spacing={{xs: 1, md: 3}}>
            <Grid item xs={9}>
                <Typography
                    variant="h6">
                    General
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    sx={{width: "100%"}}
                    label="Model Name"
                    value={config.modelName === null ? "Automatic" : config.modelName}
                    onChange={(event) => changeConfig({...config, modelName: event.target.value})}
                />
            </Grid>
            <Grid item xs={4}>
                <Stack
                    direction="row"
                    sx={{marginLeft: "auto"}}
                    spacing={1}
                    alignItems="center"
                >
                    <Typography
                        variant="body1">
                        Redo files:
                    </Typography>
                    <Switch
                        checked={config.redoFiles}
                        onChange={(event) => changeConfig({...config, redoFiles: event.target.checked})}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack
                    direction="row"
                    sx={{marginLeft: "auto"}}
                    spacing={1}
                    alignItems="center"
                >
                    <Typography
                        variant="body1">
                        Redo labels:
                    </Typography>
                    <Switch
                        checked={config.redoLabels}
                        onChange={(event) => changeConfig({...config, redoLabels: event.target.checked})}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <Stack
                    direction="row"
                    sx={{marginLeft: "auto"}}
                    spacing={1}
                    alignItems="center"
                >
                    <Typography
                        variant="body1">
                        Destroy after:
                    </Typography>
                    <Switch
                        checked={config.destroyAfter}
                        onChange={(event) => changeConfig({...config, destroyAfter: event.target.checked})}
                    />
                </Stack>
            </Grid>
            <Grid item xs={8}>
                <Typography
                    variant="h6">
                    Datasets
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Stack
                    direction="row"
                    alignItems="center"
                >
                    <Typography
                        variant="body1">
                        Runs
                    </Typography>
                    <Switch
                        checked={dataTag}
                        onChange={(event) => changeDataTag(event.target.checked)}
                    />
                    <Typography
                        variant="body1">
                        Tags
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {dataTag ? renderTagsChoice() : renderRunsChoice()}
            </Grid>
            <Grid item xs={9}>
                <Typography
                    variant="h6">
                    Labeling
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel id="labeling-model-select-label">Labeling model</InputLabel>
                    <Select
                        labelId="labeling-model-select-label"
                        id="labeling-model-select"
                        value={config.labelingConfig.labelingModel}
                        label="Labeling model"
                        onChange={(event) => changeConfig({
                            ...config,
                            labelingConfig: {...config.labelingConfig, labelingModel: event.target.value}
                        })}
                    >
                        {props.models.map((model) => {
                            return (
                                <MenuItem key={model} value={model}>
                                    {model}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <Stack
                    direction="row"
                    sx={{marginLeft: "auto", height: "100%", width: "100%"}}
                    spacing={1}
                    alignItems="center"
                >
                    <Typography
                        variant="body1">
                        Use api:
                    </Typography>
                    <Switch
                        checked={config.labelingConfig.useApi}
                        onChange={(event) => changeConfig({
                            ...config,
                            labelingConfig: {...config.labelingConfig, useApi: event.target.checked}
                        })}
                    />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    sx={{width: "100%"}}
                    label="Minimal annotations"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    value={config.labelingConfig.minAnnotationRatio === null ? "" : config.labelingConfig.minAnnotationRatio.toFixed(2)}
                    onChange={(e) => changeConfig({
                        ...config,
                        labelingConfig: {
                            ...config.labelingConfig,
                            minAnnotationRatio: Number.parseFloat(e.target.value)
                        }
                    })}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    sx={{width: "100%"}}
                    label="Max uncertainty"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    value={config.labelingConfig.maxUncertainty === null ? "" : config.labelingConfig.maxUncertainty.toFixed(2)}
                    onChange={(e) => changeConfig({
                        ...config,
                        labelingConfig: {...config.labelingConfig, maxUncertainty: Number.parseFloat(e.target.value)}
                    })}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="h6">
                    Training
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Stack
                    direction="row"
                    sx={{marginLeft: "auto", height: "100%", width: "100%"}}
                    spacing={1}
                    alignItems="center"
                >
                    <Typography
                        variant="body1">
                        Use depth:
                    </Typography>
                    <Switch
                        checked={!config.trainConfig.noDepth}
                        onChange={(event) => changeConfig({
                            ...config,
                            trainConfig: {...config.trainConfig, noDepth: !event.target.checked}
                        })}
                    />
                </Stack>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    sx={{width: "100%"}}
                    label="Initial width"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    value={config.trainConfig.initFilters === null ? "" : config.trainConfig.initFilters.toFixed(0)}
                    onChange={(e) => changeConfig({
                        ...config,
                        trainConfig: {...config.trainConfig, initFilters: Number.parseFloat(e.target.value)}
                    })}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{width: "100%"}}>
                    <InputLabel id="architecture-select-label">Architecture</InputLabel>
                    <Select
                        labelId="architecture-select-label"
                        id="architecture-select"
                        value={config.trainConfig.architecture}
                        label="Number of layers"
                        onChange={(event) => changeConfig({
                            ...config,
                            trainConfig: {...config.trainConfig, architecture: event.target.value}
                        })}
                    >
                        {["l1", "l2", "l3", "l4"].map((archDepth) => {
                            return (
                                <MenuItem key={archDepth} value={archDepth}>
                                    {archDepth}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    sx={{width: "100%"}}
                    label="Learning rate"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    value={config.trainConfig.learningRate === null ? "" : config.trainConfig.learningRate.toFixed(5)}
                    onChange={(e) => changeConfig({
                        ...config,
                        trainConfig: {...config.trainConfig, learningRate: Number.parseFloat(e.target.value)}
                    })}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    sx={{width: "100%"}}
                    label="Weight decay"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    value={config.trainConfig.weightDecay === null ? "" : config.trainConfig.weightDecay.toFixed(5)}
                    onChange={(e) => changeConfig({
                        ...config,
                        trainConfig: {...config.trainConfig, weightDecay: Number.parseFloat(e.target.value)}
                    })}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    sx={{width: "100%"}}
                    label="Epochs"
                    type="number"
                    onWheel={(e: any) => {
                        e.target.blur();
                    }}
                    value={config.trainConfig.maxEpochs === null ? "" : config.trainConfig.maxEpochs.toFixed(0)}
                    onChange={(e) => changeConfig({
                        ...config,
                        trainConfig: {...config.trainConfig, maxEpochs: Number.parseFloat(e.target.value)}
                    })}
                />
            </Grid>
            <Grid item xs={12}>
                <RunTrainingButton apiUrl={props.apiUrl} startTraining={() => {
                    if (config.labelingConfig.labelingModel === null) {
                        console.log("No labeling model");
                        changeOpenAlert("missing");
                        return;
                    }
                    let modelName = config.modelName;
                    if (modelName === null || modelName === "" || modelName === "Automatic") {
                        modelName = "tuna_" + config.trainConfig.architecture + "_e" + config.trainConfig.maxEpochs + "_f" + config.trainConfig.initFilters + "_" + (config.trainConfig.noDepth ? 'd0' : 'd1') + "_" + getCurrentDateTime();
                    }
                    changeConfig((prevConfig: MLConfig) => {
                        let newConfig = {
                            ...prevConfig,
                            modelName: modelName
                        };

                        fetch(props.apiUrl + "/ml/train", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(newConfig)
                        }).then((response) => {
                            if (response.status === 200) {
                                console.log("Training started");
                                changeOpenAlert("success");
                            } else {
                                console.log("Error starting training");
                                changeOpenAlert("not");
                            }
                        });

                        return newConfig;
                    });
                }}/>
            </Grid>
            <Snackbar open={openAlert !== null} autoHideDuration={6000} onClose={handleClose}>
                {snackbarContent}
            </Snackbar>
        </Grid>
    );
}
