import * as React from "react";
import {useState} from "react";

import {ResponsiveLine} from '@nivo/line'
import {Box, Stack, Switch, Typography} from "@mui/material";
import {ResponsiveBar} from '@nivo/bar'


import CheckboxGroup from "../CheckboxGroup"
import {shortRunId} from "../../utils/utils";

interface KPIGraphTabProps {
    properties: Array<string>,
    enabled: Array<string>,
    runs: { [runId: string]: { [indicator: string]: number } }

    onPropertiesChange(s: string): void
}

export default function KPIGraphTab(props: KPIGraphTabProps) {

    const [line, switchLine] = useState(true);
    const [stacked, switchStacked] = useState(false);

    const transformDataLine = (runs: { [runId: string]: { [indicator: string]: number } }, enabled: Array<string>) => {
        let availableColors = ["red", "green", "blue", "yellow", "black", "orange", "purple", "cyan", "darkgrey", "brown", "lightblue", "lime"];
        let data = [];
        for (let i in enabled) {
            let single: { id: string, color: string, data: any };
            let singleData = [];
            for (let id in runs) {
                singleData.push({
                    "x": shortRunId(id),
                    "y": runs[id][enabled[i]]
                });
            }
            single = {"id": enabled[i], "color": availableColors[i], "data": singleData};
            data.push(single);
        }
        return data;
    }

    const transformDataBar = (runs: { [runId: string]: { [indicator: string]: number } }, enabled: Array<string>) => {
        let availableColors = ["red", "green", "blue", "yellow", "black", "orange", "purple", "cyan", "darkgrey", "brown", "lightblue", "lime"];
        let data = [];
        for (let id in runs) {
            let single: any = {"run": shortRunId(id)}
            for (let i in enabled) {
                single[enabled[i]] = runs[id][enabled[i]];
                single[enabled[i] + "Color"] = availableColors[i];
            }
            data.push(single);
        }
        return data;
    }

    return (
        <Box sx={{alignItems: "center", justifyContent: "center"}}>
            <CheckboxGroup all={props.properties} enabled={props.enabled} onChange={props.onPropertiesChange}/>
            <Stack direction="row" sx={{alignItems: "center", justifyContent: "center"}} spacing={2}>
                <Typography variant="body1"
                            color={line ? "text.secondary" : "text.primary"}>Bar</Typography>
                <Switch checked={line} onChange={() => switchLine(!line)}/>
                <Typography color={line ? "text.primary" : "text.secondary"}
                            variant="body1">Line</Typography>
                <Box sx={{height: "100%", width: 0, border: "1px solid #000"}}/>
                <Typography variant="body1"
                            color={stacked ? "text.secondary" : "text.primary"}>Grouped</Typography>
                <Switch checked={stacked} onChange={() => switchStacked(!stacked)}/>
                <Typography color={stacked ? "text.primary" : "text.secondary"}
                            variant="body1">Stacked</Typography>
            </Stack>
            <Box sx={{height: "600px", width: "100%"}}>
                {line &&
                    <ResponsiveLine
                        data={transformDataLine(props.runs, props.enabled)}
                        margin={{top: 70, right: 30, bottom: 150, left: 50}}
                        xScale={{type: 'point'}}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            reverse: false,
                            stacked: stacked
                        }}
                        yFormat=" >-.4"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 4,
                            tickPadding: 4,
                            tickRotation: 75,
                            legend: 'Runs',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Values',
                            legendOffset: -45,
                            legendPosition: 'middle'
                        }}
                        pointSize={10}
                        pointColor={{theme: 'background'}}
                        pointBorderWidth={2}
                        pointBorderColor={{from: 'serieColor'}}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'top',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: -30,
                                itemsSpacing: 3,
                                itemDirection: 'left-to-right',
                                itemWidth: 250,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                    />}
                {!line &&
                    <ResponsiveBar
                        data={transformDataBar(props.runs, props.enabled)}
                        keys={props.enabled}
                        indexBy="run"
                        groupMode={stacked ? "stacked" : "grouped"}
                        margin={{top: 70, right: 30, bottom: 150, left: 50}}
                        padding={0.3}
                        valueScale={{type: 'linear'}}
                        indexScale={{type: 'band', round: true}}
                        colors={{scheme: 'nivo'}}
                        valueFormat=" >-.4"
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 4,
                            tickPadding: 4,
                            tickRotation: 75,
                            legend: 'Runs',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Values',
                            legendOffset: -45,
                            legendPosition: 'middle'
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'top',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: -30,
                                itemsSpacing: 3,
                                itemDirection: 'left-to-right',
                                itemWidth: 250,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        role="application"
                        barAriaLabel={function (e) {
                            return e.id + ": " + e.formattedValue + " in country: " + e.indexValue
                        }}
                    />}
            </Box>
        </Box>

    );
}