import * as React from "react";
import {useEffect, useState} from "react";
import {auth} from '../utils/firebase'
import {onAuthStateChanged} from "firebase/auth"

import {Box, Drawer, Tab} from '@mui/material';
import SignedHeader from "../components/SignedHeader";
import SideDrawer from '../components/SideDrawer'
import FeedTab from '../components/data/FeedTab'
import {TabContext, TabList, TabPanel} from '@mui/lab'

import logo from "../images/logo.png"
import SearchTab from "../components/data/SearchTab";
import UnsignedLayout from "../components/UnsignedLayout";

export default function DataPage(props: { name: string, apiUrl: string }) {
    const [drawerOpened, openDrawer] = useState(false);
    const [tab, changeTab] = useState("1");
    const [userName, changeUserName] = useState("");
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user && auth.currentUser) {
                let name = auth.currentUser.displayName;
                if (name === undefined || name === null) {
                    name = auth.currentUser.email!.split("@")[0];
                }
                changeUserName(name);
            } else {
                window.location.replace('/');
            }
        });
    }, []);

    return userName === "" ? <UnsignedLayout/> : (
        <Box sx={{display: "flex", flexFlow: "column", height: "100%"}}>
            <SignedHeader
                name={props.name}
                icon={logo}
                openDrawer={() => openDrawer(true)}/>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={tab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(e: any, v: string) => changeTab(v)} aria-label="data feed search tabs"
                                 variant="fullWidth">
                            <Tab label="Feed" value="1"/>
                            <Tab label="Search" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <FeedTab apiUrl={props.apiUrl}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <SearchTab apiUrl={props.apiUrl}/>
                    </TabPanel>
                </TabContext>
            </Box>
            <Drawer
                anchor="left"
                open={drawerOpened}
                onClose={() => openDrawer(false)}
            >
                <SideDrawer
                    closeDrawer={() => openDrawer(false)}
                    user={userName}
                />
            </Drawer>
        </Box>
    );

}