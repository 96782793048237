import {Autocomplete, Stack, TextField, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {compareRunIds} from "../utils/utils";


export default function RunAutocomplete(props: { apiUrl: string, onAdd(added: string): void }) {
    const [inputText, changeInputText] = useState<string>("");
    const [options, changeOptions] = useState<Array<string>>([]);

    useEffect(() => {
            const url = props.apiUrl + "/serialized/runPrompt?runIdPart=" + inputText;
            fetch(url, {method: "GET"}).then(response => {
                if (!response.ok) {
                    console.log("HTTP error " + response.status);
                    throw new Error("HTTP error " + response.status);
                }

                return response.json();
            }).then(json => {
                changeOptions(json.sort((a: string, b: string) => compareRunIds(b, a)));
            });
        }
        , [inputText, props.apiUrl]);

    return (
        <Autocomplete<string>
            options={options}
            renderInput={(params) => <TextField {...params} label={"Search run by id..."}/>}
            sx={{width: "60%"}}
            placeholder="Search run by id..."
            inputValue={inputText}
            noOptionsText="No run matching the criteria"
            filterOptions={(x) => x}
            autoSelect
            renderOption={(props, option) => {
                return (
                    <li {...props}><Stack direction="row" sx={{alignItems: "center", padding: 1}}
                                          key={option}>
                        <Typography variant="body2" color="text.secondary" sx={{marginLeft: 1}}>
                            {option}
                        </Typography>
                    </Stack></li>
                );
            }}
            onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => changeInputText(value)}
            onChange={(event: any, newValue: string | null) => {
                if (newValue) {
                    props.onAdd(newValue);
                }
            }}/>
    );
}