import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../utils/firebase";
import UnsignedLayout from "../components/UnsignedLayout";
import SignedHeader from "../components/SignedHeader";
import logo from "../images/logo.png";
import { Box, Drawer, Stack } from "@mui/material";
import SideDrawer from "../components/SideDrawer";
import { useParams } from "react-router-dom";
import ROSLIB from "roslib";
import Status from "../components/control/Status";
import { Button } from "@mui/material";
import { useInterval } from "../utils/utils";
import EndpointList from "../components/control/EndpointList";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import ImageDisplay from "../components/control/ImageDisplay";
import { TabContext } from "@mui/lab";
import MillingSetup from "../components/control/MillingSetup";
import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import ConstructionIcon from "@mui/icons-material/Construction";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import LogViewer from "../components/control/LogViewer";
import ExecutionButtonGroup2 from "../components/control/ExecutionButtonGroup";
import BrickView from "../components/control/BrickView";

const execModes = {
  run: 0,
  pause: 1,
  stop: 2,
  refresh: 3,
};

export default function RobotsPage(props: { name: string; apiUrl: string }) {
  const { robotName } = useParams();
  const [serverUrl, changeServer] = useState("wss://127.0.0.1:9090");

  const [drawerOpened, openDrawer] = useState(false);
  const [userName, changeUserName] = useState("");

  const [connected, changeConnected] = useState(false);
  const [running, changeRunning] = useState(false);

  const [attemptingReconnect, setAttemptingReconnect] = useState(false);

  const rosRef = useRef<ROSLIB.Ros | null>(null);

  const alertRef = useRef<ROSLIB.Topic | null>(null);
  const [currentAlert, setCurrentAlert] = useState<string | null>(null);

  const pausedRef = useRef<ROSLIB.Topic | null>(null);
  const [execMode, setExecMode] = useState(execModes.run);

  const wallRef = useRef<ROSLIB.Topic | null>(null);
  const pathRef = useRef<ROSLIB.Topic | null>(null);

  const [currentWall, setCurrentWall] = useState<any | null>(null);

  const [pathMessage, setPathMessage] = useState<any | null>(null);

  const [chosenTab, setChosenTab] = useState("0");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && auth.currentUser) {
        let name = auth.currentUser.displayName;
        if (name === undefined || name === null) {
          name = auth.currentUser.email!.split("@")[0];
        }
        changeUserName(name);
      } else {
        window.location.replace("/");
      }
    });
  }, [serverUrl]);

  function connectROS() {
    if (rosRef.current) {
      rosRef.current.close();
    }

    rosRef.current = new ROSLIB.Ros({
      url: serverUrl,
    });

    rosRef.current.on("connection", () => {
      console.log("Connected to websocket server.");
      changeConnected(true);
      setAttemptingReconnect(false);
      setTimeout(() => {
        fetchPause();
        subscribeTopics();
        resendConfigs();
      }, 600); // Add a delay before subscribing to topics
    });

    rosRef.current.on("error", (error) => {
      console.log("Error connecting to websocket server: ", error);
      changeConnected(false);
      setAttemptingReconnect(true);
      destroyTopics();
    });

    rosRef.current.on("close", () => {
      console.log("Connection to websocket server closed.");
      changeConnected(false);
      setAttemptingReconnect(true);
      destroyTopics();
    });
  }

  function subscribeTopics() {
    if (!rosRef.current) {
      return;
    }

    alertRef.current = new ROSLIB.Topic({
      ros: rosRef.current,
      name: "/remote/alert",
      messageType: "std_msgs/String",
    });

    alertRef.current.subscribe((message: any) => {
      setCurrentAlert(message.data);
    });

    pausedRef.current = new ROSLIB.Topic({
      ros: rosRef.current,
      name: "/remote/pause_out",
      messageType: "std_msgs/UInt32",
    });

    pausedRef.current.subscribe((message: any) => {
      setExecMode(message.data);
    });

    wallRef.current = new ROSLIB.Topic({
      ros: rosRef.current,
      name: "/remote/wall",
      messageType: "sitetech_interface/msg/WallWhole",
      compression: "none",
      throttle_rate: 0,
      queue_length: 1,
    });

    wallRef.current.subscribe((message: any) => {
      console.log("Received wall message: ", message);
      setCurrentWall(message);
    });

    pathRef.current = new ROSLIB.Topic({
      ros: rosRef.current,
      name: "/remote/path",
      messageType: "sitetech_interface/msg/Path",
      compression: "none",
      throttle_rate: 0,
      queue_length: 1,
    });

    pathRef.current.subscribe((message: any) => {
      console.log("Received path message: ", message);
      setPathMessage(message);
    });
  }

  const destroyTopics = () => {
    if (alertRef.current) {
      alertRef.current.unsubscribe();
      alertRef.current = null;
    }
    if (pausedRef.current) {
      pausedRef.current.unsubscribe();
      pausedRef.current = null;
    }
    if (wallRef.current) {
      wallRef.current.unsubscribe();
      wallRef.current = null;
    }
    if (pathRef.current) {
      pathRef.current.unsubscribe();
      pathRef.current = null;
    }
  };

  const fetchPause = () => {
    if (!rosRef.current) {
      return;
    }

    const service = new ROSLIB.Service({
      ros: rosRef.current,
      name: "/remote/pause_status",
      serviceType: "std_srvs/Trigger",
    });

    const request = new ROSLIB.ServiceRequest({});
    service.callService(
      request,
      (result: any) => {
        console.log(
          "Result for service call on " + service.name + ": " + JSON.stringify(result)
        );
        setExecMode(result.success ? execModes.pause : execMode);
      },
      (error: any) => {
        console.log("Error calling service on " + service.name + ": " + error);
      }
    );
  };

  const resendConfigs = () => {
    if (!rosRef.current) {
      return;
    }

    const topic = new ROSLIB.Topic({
      ros: rosRef.current,
      name: "/remote/resend_config",
      messageType: "std_msgs/Bool",
    });

    const message = new ROSLIB.Message({
      data: true,
    });

    topic.publish(message);
  }

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (rosRef.current) {
        destroyTopics();
        rosRef.current.close();
      }
    };

    // Add event listener for page refresh or close
    window.addEventListener("beforeunload", handleBeforeUnload);

    connectROS();

    // Cleanup on component unmount and page close/refresh
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (rosRef.current) {
        rosRef.current.close();
        destroyTopics();
      }
    };
  }, [serverUrl]);

  const checkStatus = () => {
    if (!rosRef.current) {
      return;
    }

    const service = new ROSLIB.Service({
      ros: rosRef.current,
      name: "/remote/status",
      serviceType: "std_srvs/Trigger",
    });

    const request = new ROSLIB.ServiceRequest({});
    service.callService(
      request,
      (result: any) => {
        console.log(
          "Result for service call on " + service.name + ": " + JSON.stringify(result)
        );
        changeRunning(result.success);
      },
      (error: any) => {
        console.log("Error calling service on " + service.name + ": " + error);
      }
    );
  };

  useInterval(() => {
    if (!connected) {
      return;
    }

    checkStatus();
  }, 400);

  useInterval(() => {
    if (!connected && attemptingReconnect && serverUrl) {
      console.log("Attempting to reconnect...");
      destroyTopics();
      connectROS();
    }
  }, 600);

  return userName === "" ? (
    <UnsignedLayout />
  ) : (
    <Box sx={{ display: "flex", flexFlow: "column", height: "100vh" }}>
      <SignedHeader
        name={props.name}
        icon={logo}
        openDrawer={() => openDrawer(true)}
      />
      <Status connected={connected} running={running} />

      <Stack
        spacing={1}
        direction="row"
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          padding: "5px",
          overflow: "auto",
        }}
      >
        <ImageDisplay
          ros={rosRef.current}
          wall={currentWall}
          running={running}
          execMode={execMode}
          path={pathMessage}
        />

        <ExecutionButtonGroup2
          ros={rosRef.current}
          running={running}
          connected={connected}
          execMode={execMode}
        />

        <Stack
          direction="column"
          sx={{ alignItems: "center", maxWidth: "60%", flex: 1 }}
          spacing={0}
        >
          <EndpointList
            chosenRobot={robotName}
            notifyChange={(o) => changeServer(o.address)}
          />

          <TabContext value={chosenTab}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", height: "10%", width: "100%"
               }}
            >
              <TabList
                onChange={(e, v) => setChosenTab(v)}
                aria-label="lab API tabs example"
                sx={{
                  width: "100%", height: "100%"
                }}
              >
                <Tab
                  label="Log viewer"
                  value="0"
                  icon={<WysiwygIcon />}
                  iconPosition="end"
                  sx={{padding: "4px", flex: 1}}
                />
                <Tab
                  label="Milling setup"
                  value="1"
                  icon={<ConstructionIcon />}
                  iconPosition="end"
                  sx={{padding: "4px", flex: 1}}
                />
                <Tab
                  label="Brick control"
                  value="2"
                  icon={<CropRotateIcon />}
                  iconPosition="end"
                  sx={{padding: "4px", flex: 1}}
                />
                <Tab
                  label="Data display"
                  value="3"
                  icon={<QueryStatsIcon />}
                  iconPosition="end"
                  sx={{padding: "4px", flex: 1}}
                />
              </TabList>
            </Box>
            <div style={{ position: "relative", height: "100%", width: "100%" }}>
              <div
                style={{
                  display: chosenTab === "0" ? "flex" : "none",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <LogViewer ros={rosRef.current} />
              </div>
              
              <div
                style={{
                  display: chosenTab === "1" ? "block" : "none",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <MillingSetup ros={rosRef.current} enableEdit={running && execMode === execModes.pause} />
              </div>
              
              <div
                style={{
                  display: chosenTab === "2" ? "block" : "none",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <BrickView workingBrick={0} wall={currentWall} ros={rosRef.current} />
              </div>
            </div>
          </TabContext>
        </Stack>
      </Stack>
      <Drawer
        anchor="left"
        open={drawerOpened}
        onClose={() => openDrawer(false)}
      >
        <SideDrawer closeDrawer={() => openDrawer(false)} user={userName} />
      </Drawer>
      <Dialog
        open={currentAlert !== null}
        onClose={() => setCurrentAlert(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Robot alert:"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {currentAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCurrentAlert(null)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
