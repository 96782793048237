import * as React from "react";
import {useState} from "react";


import {styled} from '@mui/material/styles';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    Stack,
    Typography
} from '@mui/material';
import IconButton, {IconButtonProps} from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import {handleDownload, padNumber, strToColor} from "../utils/utils"
import noImage from "../images/no-image.jpeg"

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RunCard(props: { runId: string, data: { [indicator: string]: number }, images: Array<string>, apiUrl: string }) {

    const displayedData = ["Total time [s]", "Total found bricks", "Found whole bricks", "Whole step", "95 procentile of spindle motor amps", "Error path planning count"]
    const [expanded, setExpanded] = useState<boolean>(false)
    const [imageIndex, setImgIndex] = useState<number>(0)
    const machineName = props.runId.split("_")[0].charAt(0).toUpperCase() + props.runId.split("_")[0].slice(1);

    const renderData = (param: string, v: number) => {
        return (<Stack direction="row" key={param}>
            <Typography sx={{margin: 1.6, marginBottom: 0}}>{param}:</Typography>
            <Typography sx={{
                margin: 1.6,
                marginLeft: "auto",
                marginBottom: 0
            }}>{v % 1 !== 0 ? Number.parseFloat(v + "").toFixed(3) : v}</Typography>
        </Stack>);
    }

    const fetchImageUrls = async () => {
        const url = props.apiUrl + "/images/search?fileType=png&imageType=raw&imageType=depth&runId=" + props.runId;
        return await fetch(url, {method: "POST"}).then(response => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then((json: Array<any>) => {
            return json.map((v: any) => {
                const r = v.metadata.imageType + "-" + v.metadata.runId + "-" + padNumber(v.metadata.picNum, 3) + "." + v.metadata.fileType;
                return {readable: r, url: v.url}
            });
        });
    }

    const fetchSerializedData = async () => {
        const url = props.apiUrl + "/serialized/search?extensions=npy&runId=" + props.runId;
        return await fetch(url, {method: "GET"}).then(response => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then((json: Array<any>) => {
            return json.map((v: any) => {
                const r = v.metadata.dataType + "-" + v.metadata.runId + "." + v.metadata.extension;
                return {readable: r, url: v.url}
            });
        });
    }

    const downloadRun = () => {
        setExpanded(true);
        fetchImageUrls().then((images: Array<any>) => {
            fetchSerializedData().then((serialized: Array<any>) => {
                let urls: Array<string> = []
                let names: Array<string> = []
                images.forEach((v: any) => {
                    urls.push(v.url)
                    names.push(v.readable)
                });

                serialized.forEach((v: any) => {
                    urls.push(v.url)
                    names.push(v.readable)
                });

                handleDownload(urls, names, props.runId + ".zip", () => {
                    setExpanded(false);
                });
            })
        });
    }

    return (
        <Card sx={{width: 380, margin: 2}} key={props.runId}>
            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: strToColor(machineName + "..salt..")}}
                            aria-label="machine">
                        {machineName.charAt(0)}
                    </Avatar>}
                title={props.runId}
                subheader={machineName + ", Loops: " + props.data["Loop counter"]}
            />
            <CardMedia
                sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
                component="div"
                children={
                    <Box sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        height: "100%",
                        width: "100%"
                    }}>
                        <Box sx={{display: "flex", position: "relative"}}>
                            <Button
                                onClick={() => {
                                    if (props.images !== undefined) {
                                        setImgIndex((imageIndex - 1 % props.images.length + props.images.length) % props.images.length);
                                    }
                                }}
                                sx={{
                                    minWidth: "10%",
                                    width: "10%",
                                    height: "100%",
                                    margin: 0,
                                    padding: 0,
                                    position: "absolute",
                                    top: 0,
                                    left: 0
                                }}>&#10094;</Button>
                            <img className="run-image" alt="run"
                                 src={props.images === undefined ? noImage : props.images[imageIndex]}/>
                            <Button
                                onClick={() => {
                                    if (props.images !== undefined) {
                                        setImgIndex((imageIndex + 1 % props.images.length + props.images.length) % props.images.length);
                                    }
                                }}
                                sx={{
                                    minWidth: "10%",
                                    width: "10%",
                                    height: "100%",
                                    margin: 0,
                                    padding: 0,
                                    position: "absolute",
                                    top: 0,
                                    right: 0
                                }}>&#10095;</Button>
                        </Box>
                        <Stack direction="column" sx={{display: "flex"}}>
                            {displayedData.map(e => renderData(e, props.data[e]))}
                        </Stack>
                    </Box>
                }
            />
            <CardActions disableSpacing>
                <IconButton aria-label="download run" onClick={() => downloadRun()}>
                    <DownloadIcon/>
                </IconButton>
                <IconButton aria-label="share run">
                    <ShareIcon/>
                </IconButton>
                {/*<ExpandMore*/}
                {/*    expand={expanded}*/}
                {/*    onClick={() => setExpanded(!expanded)}*/}
                {/*    aria-expanded={expanded}*/}
                {/*    aria-label="show more"*/}
                {/*>*/}
                {/*    <ExpandMoreIcon/>*/}
                {/*</ExpandMore>*/}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Alert id={props.runId + "_progress"} severity="info" sx={{width: '100%'}}>
                        Getting your download ready...
                    </Alert>
                </CardContent>
            </Collapse>
        </Card>)

}