import React, { useState } from "react";
import { Button, Paper, Stack, Snackbar, Alert } from "@mui/material";
import StartIcon from "@mui/icons-material/Start";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import CloseIcon from "@mui/icons-material/Close";
import ROSLIB from "roslib";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import AutoModeIcon from '@mui/icons-material/AutoMode';

export default function ExecutionButtonGroup2(props: {
  ros: ROSLIB.Ros | null;
  running: boolean;
  connected: boolean;
  execMode: number;
}) {

  const [severity, setSeverity] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const execModes = {
    run: 0,
    pause: 1,
    stop: 2,
    refresh: 3,
  };

  const buttons = ["Calibrate", "Start","Pause", "Resume", "Refresh", "Skip", "Stop"];

  const icons: { [name: string]: any } = {
    Calibrate: <DesignServicesIcon />,
    Start: <StartIcon />,
    Pause: <PauseIcon />,
    Resume: <PlayArrowIcon />,
    Stop: <CloseIcon />,
    Skip: <SkipNextIcon />,
    Refresh: <AutoModeIcon />
  };

  const disabled = {
    Calibrate: props.running,
    Start: props.running,
    Pause: !props.running || props.execMode !== execModes.run,
    Resume: !props.running || !(props.execMode === execModes.pause),
    Stop: !props.running,
    Skip: !props.running,
    Refresh: !props.running || !(props.execMode === execModes.pause),
  };

  const performCall = (name: string) => {
    if (name === "Calibrate") {
      autocalibrateRobot();
    } else if (name === "Start") {
      startRobot();
    } else if (name === "Pause") {
      pauseRobot();
    } else if (name === "Resume") {
      resumeRobot();
    } else if (name === "Stop") {
      stopRobot();
    } else if (name === "Refresh") {
      refreshLoop();
    } else if (name === "Skip") {
      skipLayer();
    }
  };

  const pauseRobot = () => {
    if (!props.ros) {
      return;
    }

    const publisher = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/pause_in",
      messageType: "std_msgs/msg/UInt32",
    });

    const message = new ROSLIB.Message({
      data: execModes.pause,
    });

    publisher.publish(message);
  };

  const refreshLoop = () => {
    if (!props.ros) {
      return;
    }

    const publisher = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/pause_in",
      messageType: "std_msgs/msg/UInt32",
    });

    const message = new ROSLIB.Message({
      data: execModes.refresh,
    });

    publisher.publish(message);
  }

  const skipLayer = () => {
    if (!props.ros) {
      return;
    }

    const publisher = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/pause_in",
      messageType: "std_msgs/msg/UInt32",
    });

    if (props.execMode === execModes.run) {
      const message = new ROSLIB.Message({
        data: execModes.pause,
      });
      publisher.publish(message);
      // send skip after 400ms
      setTimeout(() => {
        skipLayerMessage();
      }, 400);
    } else {
      skipLayerMessage();
    }
  }

  const skipLayerMessage = () => {
    if (!props.ros) {
      return;
    }

    const publisher = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/pause_in",
      messageType: "std_msgs/msg/UInt32",
    });

    const message = new ROSLIB.Message({
      data: execModes.run,
    });

    const skip_client = new ROSLIB.Service({
      ros: props.ros,
      name: "/remote/skip_layer",
      serviceType: "std_srvs/Trigger",
    });

    const request = new ROSLIB.ServiceRequest({});

    skip_client.callService(
      request,
      (result: any) => {
        console.log(
          "Result for service call on " + skip_client.name + ": " + result
        );
        setSeverity(result.success ? "success" : "error");
        setMessage(result.message);
        // send resume after 200ms
        setTimeout(() => {
          publisher.publish(message);
        }, 200);
      },
      (error: any) => {
        console.log(
          "Error calling service on " + skip_client.name + ": " + error
        );
        setSeverity("error");
        setMessage(error);
      }
    );
  }

  const resumeRobot = () => {
    if (!props.ros) {
      return;
    }

    const publisher = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/pause_in",
      messageType: "std_msgs/msg/UInt32",
    });

    const message = new ROSLIB.Message({
      data: execModes.run,
    });

    publisher.publish(message);
  };

  const startRobot = () => {
    if (!props.ros) {
      return;
    }

    const service = new ROSLIB.Service({
      ros: props.ros,
      name: "/remote/start_main",
      serviceType: "std_srvs/Trigger",
    });

    const request = new ROSLIB.ServiceRequest({});
    
    service.callService(
      request,
      (result: any) => {
        console.log(
          "Result for service call on " + service.name + ": " + result
        );

        setSeverity(result.success ? "success" : "error");
        setMessage(result.message);
      },
      (error: any) => {
        console.log("Error calling service on " + service.name + ": " + error);
        setSeverity("error");
        setMessage(error);
      }
    );
  };

  const stopRobot = () => {
    if (!props.ros) {
      return;
    }

    const service = new ROSLIB.Service({
      ros: props.ros,
      name: "/remote/stop_main",
      serviceType: "std_srvs/Trigger",
    });

    const request = new ROSLIB.ServiceRequest({});
    service.callService(
      request,
      (result: any) => {
        console.log(
          "Result for service call on " + service.name + ": " + result
        );
        setMessage(result.message);
        setSeverity(result.success ? "success" : "error");
      },
      (error: any) => {
        console.log("Error calling service on " + service.name + ": " + error);
        setMessage(error);
        setSeverity("error");
      }
    );
  };

  const autocalibrateRobot = () => {
    if (!props.ros) {
      return;
    }

    const service = new ROSLIB.Service({
      ros: props.ros,
      name: "/remote/autocalibrate",
      serviceType: "std_srvs/Trigger",
    });

    const request = new ROSLIB.ServiceRequest({});
    service.callService(
      request,
      (result: any) => {
        console.log(
          "Result for service call on " + service.name + ": " + result
        );
        setMessage(result.message);
        setSeverity(result.success ? "success" : "error");
      },
      (error: any) => {
        console.log("Error calling service on " + service.name + ": " + error);
        setMessage(error);
        setSeverity("error");
      }
    );
  };

  return (
    <Stack
      direction="column"
      sx={{
        alignItems: "center",
        width: "10%",
        justifyContent: "center",
        overflow: "auto",
      }}
      spacing={3}
    >
      {buttons.map((n) => (
        <Paper elevation={8} key={n} sx={{ margin: 0, width: "90%" }}>
          <Button
            startIcon={icons[n]}
            sx={{ 
              width: "100%", 
              height: "65px",
              fontSize: "10px"
             }}
            disabled={disabled[n as keyof typeof disabled] || !props.connected}
            onClick={() => performCall(n)}
          >
            {n}
          </Button>
        </Paper>
      ))}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={severity !== null && message !== null}
        onClose={() => { setSeverity(null); setMessage(null); }}
        autoHideDuration={4000}
      >
        <Alert onClose={() => { setSeverity(null); setMessage(null); }} severity={(severity || "info") as any} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
