import * as React from "react";
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import StorageIcon from '@mui/icons-material/Storage';
import MemoryIcon from '@mui/icons-material/Memory';
import {auth} from '../utils/firebase'


export default function SideDrawer(props: { closeDrawer(): void, user: string }) {
    const items = [
        {text: "Data service", icon: <StorageIcon/>, link: "/data"},
        {text: "Robot control", icon: <SmartToyIcon/>, link: "/robots"},
        {text: "KPI", icon: <QueryStatsIcon/>, link: "/kpi"},
        {text: "ML", icon: <MemoryIcon/>, link: "/ml"}
    ];

    return (
        <Box
            sx={{width: 250, display: "flex", flexDirection: "column", flex: 1, paddingBottom: 2,}}
            role="presentation"
            onClick={props.closeDrawer}
        >
            <Box sx={{flexGrow: 1}}>
                <List>
                    {items.map((item, index) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton onClick={(e) => window.location.replace(item.link)}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box>
                <Divider/>
                <Typography sx={{margin: 2}} variant="body2" component="div">
                    {props.user}
                </Typography>
                <ListItemButton>
                    <ListItemIcon>
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Log out" onClick={() => auth.signOut()}/>
                </ListItemButton>
            </Box>
        </Box>
    );
}