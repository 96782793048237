import * as React from "react";
import {useEffect, useState} from "react";
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    Box,
    Chip,
    IconButton,
    List,
    ListItem,
    Paper,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";

import ImageIcon from '@mui/icons-material/Image';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {compareRunIds, padNumber, strToColor} from "../../utils/utils";
import DayDialog from "./DayDialog";
import TextDialog from "./TextDialog";
import OptionsDialog from "./OptionsDialog";
import RunIdDialog from "./RunIdDialog";
import DownloadIcon from "@mui/icons-material/Download";
import {saveAs} from "file-saver";


export default function SearchTab(props: { apiUrl: string }) {
    const [searchType, changeSearchType] = useState("serialized");
    const [inputText, changeInputText] = useState<string>("");
    const [queryOptions, changeQueryOptions] = useState<Array<string>>([]);

    const [fetchedFiles, changeFetchedFiles] = useState<Array<string>>([]);

    const options = ["day", "robot", "extension", "dataType", "imageType", "runId"]

    const [dayDialogOpened, changeDayDialogOpened] = useState<boolean>(false);
    const [robotDialogOpened, changeRobotDialogOpened] = useState<boolean>(false);
    const [extensionsDialogOpened, changeExtensionsDialogOpened] = useState<boolean>(false);
    const [imageTypesDialogOpened, changeImageTypesDialogOpened] = useState<boolean>(false);
    const [dataTypesDialogOpened, changeDataTypesDialogOpened] = useState<boolean>(false);
    const [runIdDialogOpened, changeRunIdDialogOpened] = useState<boolean>(false);

    const fetchFiles = () => {
        let url = props.apiUrl + "/" + searchType + "/search?"
        for (let q in queryOptions) {
            const name = queryOptions[q].split(":")[0];
            const value = queryOptions[q].split(":")[1];
            if (name === "robot") {
                url += "robotId=" + value + "&";
            }

            if (name === "day") {
                const startDate = new Date(value);
                const daySpan = 60 * 60 * 24 * 1000;
                const endDate = new Date(startDate.getTime() + daySpan);
                if (searchType === "serialized") {
                    url += "from=" + startDate.toISOString() + "&to=" + endDate.toISOString() + "&";
                } else {
                    url += "takenFrom=" + startDate.toISOString() + "&takenTo=" + endDate.toISOString() + "&";
                }
            }

            if (name === "extension") {
                const extensions = value.split(",");
                for (let e in extensions) {
                    if (searchType === "serialized") {
                        url += "extensions=" + extensions[e] + "&";
                    } else {
                        url += "fileType=" + extensions[e] + "&";
                    }
                }
            }

            if (name === "dataType" && searchType === "serialized") {
                const dataTypes = value.split(",");
                for (let e in dataTypes) {
                    url += "dataTypes=" + dataTypes[e] + "&";
                }
            }

            if (name === "imageType" && searchType !== "serialized") {
                const imageTypes = value.split(",");
                for (let e in imageTypes) {
                    url += "imageType=" + imageTypes[e] + "&";
                }
            }

            if (name === "runId") {
                url += "runId=" + value + "&";
            }
        }

        console.log(url);

        if (searchType === "serialized") {
            fetch(url, {method: "GET"}).then(response => {
                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            }).then((json: Array<any>) => {
                if (json.length > 3000) {
                    changeFetchedFiles([]);
                    return;
                }

                let sortedFiles = json.sort((a: any, b: any) => {
                    if (a.metadata.runId !== b.metadata.runId) {
                        return compareRunIds(a.metadata.runId, b.metadata.runId)
                    }
                    return a.metadata.dataType.localeCompare(b.metadata.dataType);
                });
                changeFetchedFiles(sortedFiles);
            });
        } else {
            fetch(url, {method: "POST"}).then(response => {
                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            }).then((json: Array<any>) => {
                if (json.length > 3000) {
                    changeFetchedFiles([]);
                    return;
                }
                let sortedFiles = json.sort((a: any, b: any) => {
                    if (a.metadata.runId !== b.metadata.runId) {
                        return compareRunIds(a.metadata.runId, b.metadata.runId)
                    }

                    if (a.metadata.imageType !== b.metadata.imageType) {
                        return a.metadata.imageType.localeCompare(b.metadata.imageType);
                    }

                    return a.metadata.picNum - b.metadata.picNum;
                });
                changeFetchedFiles(sortedFiles);
            });
        }
    }

    useEffect(() => {
        fetchFiles();
    }, [queryOptions, searchType]);

    const onDaySelected = (day: string | null) => {
        changeDayDialogOpened(false);
        if (day) {
            changeQueryOptions(["day:" + day, ...queryOptions]);
        }
    }

    const onRobotSelected = (robot: string | null) => {
        changeRobotDialogOpened(false);
        if (robot) {
            changeQueryOptions(["robot:" + robot, ...queryOptions]);
        }
    }

    const onExtensionsSelected = (extension: Array<string> | null) => {
        changeExtensionsDialogOpened(false);
        if (extension) {
            changeQueryOptions(["extension:" + extension.join(","), ...queryOptions]);
        }
    }

    const onImageTypesSelected = (imageType: Array<string> | null) => {
        changeImageTypesDialogOpened(false);
        if (imageType) {
            changeQueryOptions(["imageType:" + imageType.join(","), ...queryOptions]);
        }
    }

    const onDataTypesSelected = (dataTypes: Array<string> | null) => {
        changeDataTypesDialogOpened(false);
        if (dataTypes) {
            changeQueryOptions(["dataType:" + dataTypes.join(","), ...queryOptions]);
        }
    }

    const onRunIdSelected = (runId: string | null) => {
        changeRunIdDialogOpened(false);
        if (runId) {
            changeQueryOptions(["runId:" + runId, ...queryOptions]);
        }
    }

    const onPromptSelected = (prompt: string) => {
        if (prompt === "day") {
            changeDayDialogOpened(true);
        } else if (prompt === "robot") {
            changeRobotDialogOpened(true);
        } else if (prompt === "extension") {
            changeExtensionsDialogOpened(true);
        } else if (prompt === "imageType") {
            changeImageTypesDialogOpened(true);
        } else if (prompt === "dataType") {
            changeDataTypesDialogOpened(true);
        } else if (prompt === "runId") {
            changeRunIdDialogOpened(true);
        }
    }

    const renderQueryOption = (option: string) => {
        return (
            <Chip sx={{margin: 1, background: strToColor(option)}} key={option} label={option}
                  onDelete={() => handleDelete(option)}/>
        )
    }

    const handleDelete = (option: string) => {
        changeQueryOptions(queryOptions.filter(o => o !== option));
    }

    const canDisplayOption = (option: string) => {
        for (let queryOption of queryOptions) {
            if (queryOption.indexOf(option) === 0) {
                return false;
            }
        }
        return true;
    }

    const fileNameSerialized = (file: any) => {
        return file.metadata.dataType + "-" + file.metadata.runId + "." + file.metadata.extension;
    }

    const fileNameImage = (file: any) => {
        return file.metadata.imageType + "-" + file.metadata.runId + "-" + padNumber(file.metadata.picNum, 3) + "." + file.metadata.fileType;
    }

    const renderFile = (file: any) => {
        return (<ListItem
            key={file.id}
            sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start"}}
        >
            <Typography>
                {"picNum" in file.metadata ? fileNameImage(file) : fileNameSerialized(file)}
            </Typography>
            <IconButton sx={{marginLeft: "auto"}} onClick={() => {
                window?.open(file.url, '_blank')?.focus();
            }}>
                <OpenInNewIcon/>
            </IconButton>
            <IconButton
                onClick={() => saveAs(file.url, "picNum" in file.metadata ? fileNameImage(file) : fileNameSerialized(file))}>
                <DownloadIcon/>
            </IconButton>

        </ListItem>);
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Stack direction="row" spacing={2}
                   sx={{width: "100%", alignItems: "center", justifyContent: "center"}}>
                <ToggleButtonGroup
                    value={searchType}
                    exclusive
                    onChange={(e: any, v: string) => {
                        if (v !== null) {
                            changeSearchType(v);
                        }
                    }}
                    aria-label="text alignment"
                >
                    <ToggleButton value="images" aria-label="left aligned">
                        <ImageIcon/>
                    </ToggleButton>
                    <ToggleButton value="serialized" aria-label="left aligned">
                        <InsertDriveFileIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>
                <Autocomplete
                    options={options}
                    renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params}
                                                                                       label={"Search " + searchType + " files (Try prompts like robot, day, extension, etc)"}/>}
                    sx={{width: "80%"}}
                    placeholder={"Search " + searchType + " files (Try prompts like robot, day, extension, etc)"}
                    inputValue={inputText}
                    noOptionsText="No prompt matching the text"
                    filterOptions={(x) => x.filter(a => a.indexOf(inputText) !== -1 && canDisplayOption(a))}
                    renderOption={(props, option) => {
                        return (
                            <li {...props}><Stack direction="row" sx={{alignItems: "center", padding: 1}}
                                                  key={option}>
                                <Typography variant="body2" color={strToColor(option)} sx={{marginLeft: 1}}>
                                    {option}
                                </Typography>
                            </Stack></li>
                        );
                    }}
                    onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => changeInputText(value)}
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue) {
                            onPromptSelected(newValue);
                        }
                    }}/>
            </Stack>
            <Paper
                elevation={8}
                sx={{width: "90%", margin: 2, padding: 2}}
            >
                <Box
                    sx={{width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap"}}
                >
                    {queryOptions.map(o => renderQueryOption(o))}
                </Box>
            </Paper>
            <Paper
                elevation={8}
                sx={{width: "90%", margin: 0, padding: 2}}
            >
                <Box
                    sx={{width: "100%"}}
                >
                    {fetchedFiles.length === 0 &&
                        <Typography variant="body2" sx={{marginLeft: 1}}>
                            No files fetched (add or remove prompts to fetch files)
                        </Typography>
                    }
                    {fetchedFiles.length > 0 && <List
                        sx={{width: "100%"}}
                    >
                        {fetchedFiles.map((file: any) => renderFile(file))}
                    </List>}
                </Box>
            </Paper>
            <DayDialog open={dayDialogOpened} onClose={onDaySelected}
                       desc="All returned files will be from that day."></DayDialog>
            <TextDialog open={robotDialogOpened} onClose={onRobotSelected}
                        desc="All returned files will be created by a machine with this name."></TextDialog>
            <OptionsDialog open={extensionsDialogOpened} onClose={onExtensionsSelected}
                           desc="All returned files will have one of following extensions."
                           options={["png", "npy", "pickle", "yaml", "log", "csv", "pdf"]}></OptionsDialog>
            <OptionsDialog open={imageTypesDialogOpened} onClose={onImageTypesSelected}
                           desc="All returned files will be of one of the following image types."
                           options={["annotated", "depth", "raw", "qa", "full"]}></OptionsDialog>
            <OptionsDialog open={dataTypesDialogOpened} onClose={onDataTypesSelected}
                           desc="All returned files will have one of the following data types."
                           options={["arduino", "config", "error", "final", "gui", "machine_state", "main", "odometry", "odometri_error_array", "path_planning_only_file", "vfd"]}></OptionsDialog>
            <RunIdDialog desc={"All files will be associated with this run."} open={runIdDialogOpened}
                         onClose={onRunIdSelected} apiUrl={props.apiUrl}/>
        </Box>
    );

}
