import {Box, IconButton, List, ListItem, ListItemSecondaryAction, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import MLConfigForm from "./MLConfigForm";
import GetAppIcon from '@mui/icons-material/GetApp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import {useInterval} from "../../utils/utils";

export interface MLProgress {
    modelName: string,
    status: string,
    maxEpochs: number,
    currentEpoch: number,
    runs: Array<string>,
    loaded: Array<string>,
    labeled: Array<string>
}

export default function TrainingTab(props: { apiUrl: string }) {
    const baseUrl = "https://wall-ml-models.fra1.cdn.digitaloceanspaces.com/"
    const wandbUrl = "https://wandb.ai/sitetech253/digital_ocean"
    const [models, changeModels] = React.useState<Array<string>>([]);
    const [tags, changeTags] = React.useState<Array<string>>([]);
    const [currentProgress, changeCurrentProgress] = React.useState<MLProgress | null>(null);

    useInterval(() => {
        fetch(props.apiUrl + "/ml/train/progress", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((json: MLProgress) => {
            changeCurrentProgress(json);
        });
    }, 1000);

    useEffect(() => {
        fetch(props.apiUrl + "/ml/models", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then((json: Array<string>) => {
            changeModels(json);
        });

        fetch(props.apiUrl + "/ml/tag", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then((json: Array<string>) => {
            changeTags(json);
        });
    }, [props.apiUrl]);


    const renderMLConfig = () => {
        return (
            <Paper elevation={8}
                   sx={{height: "100%", width: "50%", alignItems: "center", justifyContent: "center", padding: 3}}>
                <MLConfigForm apiUrl={props.apiUrl} tags={tags} models={models}/>
            </Paper>);
    }

    const renderCurrentTraining = () => {
        if (currentProgress === null) return null;
        var startBackground = "lightgray";
        var loadBackground = "lightgray";
        var labelBackground = "lightgray";
        var trainingBackground = "lightgray";

        if (currentProgress.status === "starting") {
            startBackground = "#BC4A3C";
        } else if (currentProgress.status === "loading") {
            startBackground = "#9ACD32";
            loadBackground = "#BC4A3C";
        } else if (currentProgress.status === "labeling") {
            startBackground = "#9ACD32";
            loadBackground = "#9ACD32";
            labelBackground = "#BC4A3C";
        } else if (currentProgress.status === "training") {
            startBackground = "#9ACD32";
            loadBackground = "#9ACD32";
            labelBackground = "#9ACD32";
            trainingBackground = "#BC4A3C";
        } else if (currentProgress.status === "done") {
            startBackground = "#9ACD32";
            loadBackground = "#9ACD32";
            labelBackground = "#9ACD32";
            trainingBackground = "#9ACD32";
        }

        var totalRuns = null;
        var totalLoaded = null;
        var totalLabeled = null;
        if (currentProgress.runs !== undefined) totalRuns = currentProgress.runs.length;
        if (currentProgress.loaded !== undefined) totalLoaded = currentProgress.loaded.length;
        if (currentProgress.labeled !== undefined) totalLabeled = currentProgress.labeled.length;


        return (
            <Stack
                direction="column"
                spacing={2}
                sx={{width: "100%", height: "auto", alignItems: "center", justifyContent: "center", padding: 3}}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        width: "100%",
                        height: "auto",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 2
                    }}
                >
                    <Typography variant="h6">
                        {currentProgress.modelName}
                    </Typography>
                    <IconButton color="primary" aria-label="config" component="a"
                                href={currentProgress.status === "done" ? `${baseUrl}${currentProgress.modelName}.json` : `${baseUrl}ml-config.json`}
                                target="_blank">
                        <DisplaySettingsIcon/>
                    </IconButton>
                    <IconButton disabled={currentProgress.status !== "training" && currentProgress.status !== "done"}
                                color="primary" aria-label="open-wandb" component="a" href={wandbUrl} target="_blank">
                        <AssessmentIcon/>
                    </IconButton>
                    <IconButton disabled={currentProgress.status !== "done"} color="primary" aria-label="download"
                                component="a" href={`${baseUrl}${currentProgress.modelName}.pt`} download>
                        <GetAppIcon/>
                    </IconButton>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        width: "100%",
                        height: "130px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 2
                    }}
                >
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            width: "20%",
                            background: startBackground,
                            borderRadius: 2,
                            padding: 2,
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <Typography variant="body1">
                            Starting
                        </Typography>
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            width: "20%",
                            background: loadBackground,
                            borderRadius: 2,
                            padding: 2,
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <Typography variant="body1">
                            Loading
                        </Typography>
                        {totalLoaded === null ? null :
                            <Typography variant="body1">
                                {totalLoaded}/{totalRuns}
                            </Typography>}
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            width: "20%",
                            background: labelBackground,
                            borderRadius: 2,
                            padding: 2,
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <Typography variant="body1">
                            Labeling
                        </Typography>
                        {totalLabeled === null ? null :
                            <Typography variant="body1">
                                {totalLabeled}/{totalRuns}
                            </Typography>}
                    </Stack>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            width: "20%",
                            background: trainingBackground,
                            borderRadius: 2,
                            padding: 2,
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <Typography variant="body1">
                            Training
                        </Typography>
                        <Typography variant="body1">
                            {currentProgress.currentEpoch}/{currentProgress.maxEpochs}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        );
    }

    const renderMLModels = () => {
        return (
            <Paper elevation={8}
                   sx={{height: "auto", width: "50%", alignItems: "center", justifyContent: "center", padding: 3}}>
                <Stack direction="column" spacing={2}>
                    {renderCurrentTraining()}
                    <List>
                        {models.filter(v => v !== currentProgress?.modelName).map((model) => {
                            return (
                                <ListItem>
                                    <Typography>
                                        {model}
                                    </Typography>
                                    <ListItemSecondaryAction>
                                        <IconButton color="primary" aria-label="config" component="a"
                                                    href={`${baseUrl}${model}.json`} target="_blank">
                                            <DisplaySettingsIcon/>
                                        </IconButton>
                                        <IconButton color="primary" aria-label="download" component="a"
                                                    href={`${baseUrl}${model}.pt`} download>
                                            <GetAppIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </Stack>
            </Paper>
        );
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Stack direction="row" spacing={2}
                   sx={{width: "100%", justifyContent: "center", alignItems: "flex-start"}}>
                {renderMLConfig()}
                {renderMLModels()}
            </Stack>
        </Box>
    )
}
