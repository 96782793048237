import * as React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface CheckboxGroupProps {
    all: Array<string>,

    enabled: Array<string>,

    onChange(s: string): void
}

export default function CheckboxGroup(props: CheckboxGroupProps) {
    const renderItem = (name: string, value: boolean) => {
        return (
            <FormControlLabel
                key={name}
                control={
                    <Checkbox
                        checked={value}
                        id={name}
                        onChange={() => props.onChange(name)}
                    />
                }
                label={
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="body1">{name}</Typography>
                    </Box>
                }
            />
        );
    }

    return (
        <Accordion sx={{margin: 2, width: "98%"}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="table-properties-content"
                id="table-header"
            >
                <Typography variant="h6">Properties</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Grid container spacing={1}>
                        {props.all.map(name => (
                            <Grid item xs={2}
                                  key={name}>{renderItem(name, (props.enabled.indexOf(name) !== -1))}</Grid>))}
                    </Grid>
                </FormGroup>
            </AccordionDetails>
        </Accordion>

    );
}