import * as React from "react";

import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

interface SignedHeaderProps {
    icon: string,
    name: string

    openDrawer(): void,
}

export default function SignedHeader(props: SignedHeaderProps) {

    const displayDesktop = () => {
        return (
            <Toolbar>
                <IconButton sx={{marginRight: 2}} onClick={props.openDrawer}>
                    <MenuIcon/>
                </IconButton>
                {props.icon !== undefined &&
                    <img alt="logo" className="logo-image" src={props.icon}/>}
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    {props.name}
                </Typography>
            </Toolbar>);
    };

    return (
        <header>
            <AppBar position="static" color="primary">{displayDesktop()}</AppBar>
        </header>);
}