import React, { useState, useEffect } from "react";
import ROSLIB from "roslib";

import { Stack, Typography } from "@mui/material";
import BrickOverlay from "./BrickOverlay";

const ImageDisplay = (
  props: {
    ros: ROSLIB.Ros | null;
    wall: any | null;
    path: any | null;
    running: boolean;
    execMode: number;
  }) => {
  const [rgbImageSrc, setImageSrc] = useState("");
  const [mapImageSrc, setMapImageSrc] = useState("");

  useEffect(() => {
    if (!props.ros) {
      return;
    }

    const rgbListener = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/rgb",
      messageType: "std_msgs/msg/String",
    });

    rgbListener.subscribe((message: any) => {
      const imageUrl = `data:image/jpeg;base64,${message.data}`;
      setImageSrc(imageUrl);
    });

    const mapListener = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/map",
      messageType: "std_msgs/msg/String",
    });

    mapListener.subscribe((message: any) => {
      const imageUrl = `data:image/jpeg;base64,${message.data}`;
      setMapImageSrc(imageUrl);
    });

    return () => {
      rgbListener.unsubscribe();
      mapListener.unsubscribe();
    };
  }, [props.ros]);

  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        width: "30%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      {rgbImageSrc ? (
        <BrickOverlay
          ros={props.ros}
          wall={props.wall}
          path={props.path}
          imageSrc={rgbImageSrc}
          enableDrawing={props.running && props.execMode === 1}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "48%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "lightgrey",
          }}
        >
          <Typography sx={{ textAlign: "center", flexGrow: 1 }}>
            Waiting for a new RGB image...
          </Typography>
        </div>
      )}
      {mapImageSrc ? (
        <div style={{ width: "100%", height: "48%" }}>
          <img src={mapImageSrc} alt="" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "48%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "lightgrey",
          }}
        >
          <Typography sx={{ textAlign: "center", flexGrow: 1 }}>
            Waiting for a new brickmap image...
          </Typography>
        </div>
      )}
    </Stack>
  );
};

export default ImageDisplay;
