import * as React from "react";
import {AppBar, Toolbar, Typography} from "@mui/material";

export default function Header(props: { icon: string | undefined, name: string }) {
    const displayDesktop = () => {
        return (
            <Toolbar>
                {props.icon !== undefined &&
                    <img alt="logo" className="logo-image" src={props.icon}/>}
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    {props.name}
                </Typography>
            </Toolbar>);
    };

    return (
        <header>
            <AppBar position="static" color="primary">{displayDesktop()}</AppBar>
        </header>
    );
}