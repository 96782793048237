import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";


export default function TextDialog(props: { desc: string, open: boolean, onClose: (day: string | null) => void }) {
    const [pickedText, changePickedText] = useState<string>("");
    return (
        <Dialog open={props.open} onClose={() => props.onClose(null)}>
            <DialogTitle>Enter a value</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.desc}
                </DialogContentText>
                <TextField
                    label="Query value"
                    autoFocus={true}
                    value={pickedText}
                    sx={{width: 220, margin: 2}}
                    onChange={(e: any) => changePickedText(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(null)}>Cancel</Button>
                <Button onClick={() => props.onClose(pickedText)}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}