import {Box, Button, IconButton, List, ListItem, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect} from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RunAutocomplete from "../RunAutocomplete";
import DeleteIcon from '@mui/icons-material/Delete';


export default function TagsTab(props: { apiUrl: string }) {
    const [tags, changeTags] = React.useState<Array<string>>([]);
    const [newTag, changeNewTag] = React.useState("");
    const [chosenTag, changeChosenTag] = React.useState<null | string>(null);
    const [chosenRuns, changeChosenRuns] = React.useState<Array<string>>([]);

    useEffect(() => {
        fetch(props.apiUrl + "/ml/tag", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then((json: Array<string>) => {
            changeTags(json);
        });
    }, [props.apiUrl]);

    const deleteTag = (tag: string) => {
        fetch(props.apiUrl + "/ml/tag/" + tag, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then((json: Array<string>) => {
            changeTags([...tags.filter(t => t !== tag)]);
            if (chosenTag === tag) {
                changeChosenTag(null);
                changeChosenRuns([]);
            }
        });
    }

    const renderTag = (tag: string) => {
        return (<ListItem
            key={tag}
            sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start"}}
        >
            <Typography>
                {tag}
            </Typography>
            <Stack
                direction="row"
                sx={{marginLeft: "auto"}}
            >
                <IconButton
                    onClick={() => {
                        deleteTag(tag);
                    }}>
                    <DeleteIcon/>
                </IconButton>
                <IconButton
                    onClick={() => {
                        changeChosenTag(tag);
                        fetch(props.apiUrl + "/ml/tag/" + tag, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }).then((response) => {
                            if (!response.ok) {
                                throw new Error("HTTP error " + response.status);
                            }
                            return response.json();
                        }).then((json: Array<string>) => {
                            changeChosenRuns(json);
                        });
                    }}>
                    <ArrowRightIcon/>
                </IconButton>
            </Stack>
        </ListItem>);
    }

    const postTag = (tagName: string | null, content: Array<string>) => {
        if (tagName === null) {
            return;
        }

        if (tagName.length > 0) {
            fetch(props.apiUrl + "/ml/tag/" + tagName, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(content),
            }).then((response) => {
                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            }).then((json: Array<string>) => {
                if (!tags.includes(tagName)) {
                    changeTags([...tags, tagName]);
                }

                if (chosenTag === tagName) {
                    changeChosenRuns(content);
                }
            })
        }
    }

    const renderTagsList = () => {
        return (
            <Paper elevation={8}
                   sx={{width: "50%", alignItems: "center", justifyContent: "center", padding: 3}}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <TextField
                            sx={{width: "70%"}}
                            label="New tag"
                            value={newTag}
                            onChange={(e) => changeNewTag(e.target.value)}
                        />
                        <Button
                            sx={{width: "20%"}}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                changeNewTag("");
                                postTag(newTag, [])
                            }
                            }
                        >Create</Button>
                    </Stack>
                    <List>
                        {tags.map(t => renderTag(t))}
                    </List>
                </Stack>
            </Paper>
        );
    }

    const renderRun = (r: string) => {
        return (
            <ListItem
                key={r}
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start"
                }}
            >
                <Typography>
                    {r}
                </Typography>
                <IconButton
                    sx={{marginLeft: "auto"}}
                    onClick={() => {
                        postTag(chosenTag, [...chosenRuns.filter(t => t !== r)]);
                    }}>
                    <DeleteIcon/>
                </IconButton>
            </ListItem>
        );
    }

    const renderTagContent = () => {
        return (
            <Paper elevation={8}
                   sx={{width: "50%", alignItems: "center", justifyContent: "center", padding: 3}}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography
                            sx={{width: "30%"}}
                        >
                            {chosenTag === null ? "No tag selected" : "Tag: " + chosenTag}
                        </Typography>
                        <RunAutocomplete apiUrl={props.apiUrl} onAdd={(e) => {
                            if (chosenTag !== null) {
                                postTag(chosenTag, [...chosenRuns, e]);
                            }
                        }}/>
                    </Stack>
                    <List>
                        {chosenRuns.map(r => renderRun(r))}
                    </List>
                </Stack>
            </Paper>
        );
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Stack direction="row" spacing={2}
                   sx={{width: "100%", alignItems: "top", justifyContent: "center"}}>
                {renderTagsList()}
                {renderTagContent()}
            </Stack>
        </Box>
    )
}