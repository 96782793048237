import React, {useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from "@mui/material";
import RunAutocomplete from "../RunAutocomplete";


export default function RunIdDialog(props: { desc: string, open: boolean, onClose: (day: string | null) => void, apiUrl: string }) {
    const [pickedRun, changePickedRun] = useState<string>("");
    return (
        <Dialog open={props.open} onClose={() => props.onClose(null)}>
            <DialogTitle>Enter a value</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.desc}
                </DialogContentText>
                <Box sx={{width: "380px", margin: 2}}>
                    <RunAutocomplete apiUrl={props.apiUrl} onAdd={(r) => changePickedRun(r)}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(null)}>Cancel</Button>
                <Button onClick={() => props.onClose(pickedRun)}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}