import React, { useRef, useState, useEffect } from "react";
import { Alert, Menu, MenuItem } from "@mui/material";
import SubMenu from "./SubMenu";
import ROSLIB from "roslib";
import Snackbar from "@mui/material/Snackbar";

const BrickOverlay = (
  props: {
    ros: ROSLIB.Ros | null,
    wall: any | null;
    path: any | null;
    imageSrc: string;
    enableDrawing: boolean
  }) => {

  const brickBit = {
    top: [0],
    left: [1],
    bottom: [2],
    right: [3],
    top_segments: [4, 11],
    bottom_segments: [12, 19],
  };

  const brickPoints = {
    center: [0, 1],
    top_right: [2, 3],
    top_left: [4, 5],
    bottom_left: [6, 7],
    bottom_right: [8, 9],
  };

// Colors
const mortar_detected_color = "darkcyan";
const mortar_removed_color = "limegreen";
const brick_color = "black";
const text_color = "white";
const completed_path_color = "red";
const current_position_color = "lightgreen";
const planned_path_color = "blue";

// Text Sizes
const normal_text_size = 9;
const zoomed_text_size = 14;

// Zoom Factor
const zoomFactor = 7;

// Mortar Widths
const mortar_normal_width = 1;
const mortar_zoomed_width = 3;

// Brick Widths
const brick_normal_width = 0.4;
const brick_zoomed_width = 1.3;

// Brick Dash Arrays
const brick_zoomed_dasharray = "15,15";
const brick_normal_dasharray = "2,2";

// Radii
const current_normal_radius = 2;
const current_zoomed_radius = 5;
const path_normal_radius = 1;
const path_zoomed_radius = 3;

  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [scale, setScale] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);


  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0, offsetX: 0, offsetY: 0 });

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [zoomProperties, setZoomProperties] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const [drawingMode, setDrawingMode] = useState(false);
  const [startPoint, setStartPoint] = useState<{ x: number, y: number } | null>(null);
  const [middlePoint, setMiddlePoint] = useState<{ x: number, y: number } | null>(null);
  const [middlePoint2, setMiddlePoint2] = useState<{ x: number, y: number } | null>(null);
  const [endPoint, setEndPoint] = useState<{ x: number, y: number } | null>(null);
  const [menuPosition, setMenuPosition] = useState<{ x: number, y: number } | null>(null);

  const [severity, setSeverity] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);


  const addBrickPosition = (id: number) => {
    if (!props.ros) {
      return;
    }

    if (startPoint === null || middlePoint === null || middlePoint2 === null || endPoint === null) {
      return;
    }

    const position = [startPoint.x, 1 - startPoint.y, middlePoint.x, 1 - middlePoint.y, middlePoint2.x, 1 - middlePoint2.y, endPoint.x, 1 - endPoint.y];
    
    console.log("Adding brick with position: ", position);

    const service = new ROSLIB.Service({
      ros: props.ros,
      name: "/remote/add_to_wall",
      serviceType: "sitetech_interface/srv/RemoteAddToWall",
    });

    const request = new ROSLIB.ServiceRequest({
      id: id,
      brick_points: position,
    });

    handleClose();

    service.callService(
      request,
      (result: any) => {
        setSeverity("success");
        setMessage("Brick added as ID: " + result.brick_id);
      },
      (error: any) => {
        setSeverity("error");
        setMessage("Error calling service on " + service.name + ": " + error);
      }
    );
  }

  // Function to update the scale and image height based on the image's actual rendered size
  const updateScaleAndPosition = () => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      const containerWidth = imgRef.current.clientWidth;
      const containerHeight = imgRef.current.clientHeight;
      let scaleWidth = containerWidth / naturalWidth;
      let scaleHeight = containerHeight / naturalHeight;
      let scale = Math.min(scaleWidth, scaleHeight);
      let imageWidth = scale * naturalWidth;
      let imageHeight = scale * naturalHeight;
      let offsetX = (containerWidth - imageWidth) / 2;
      let offsetY = (containerHeight - imageHeight) / 2;

      setScale(scale);
      setImageDimensions({
        width: imageWidth,
        height: imageHeight,
        offsetX: offsetX,
        offsetY: offsetY
      });

      // Update canvas size
      if (canvasRef.current) {
        canvasRef.current.width = imageWidth;
        canvasRef.current.height = imageHeight;
        canvasRef.current.style.left = `${offsetX}px`;
        canvasRef.current.style.top = `${offsetY}px`;
      }
    }
  };

  const drawZoomedArea = (x: number, y: number) => {
    if (!imgRef.current || !canvasRef.current || !isZoomed) {
      return;
    }

    if (canvasRef.current) {
      canvasRef.current.width = imageDimensions.width;
      canvasRef.current.height = imageDimensions.height;
      canvasRef.current.style.left = `${imageDimensions.offsetX}px`;
      canvasRef.current.style.top = `${imageDimensions.offsetY}px`;
    }

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      return;
    }
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    const zoomedSize = { x: (canvasRef.current.width * zoomFactor), y: (canvasRef.current.height * zoomFactor) };

    const minLeftTop = {
      x: canvasRef.current.width - zoomedSize.x,
      y: canvasRef.current.height - zoomedSize.y
    }

    const leftTop = {
      x: minLeftTop.x * x,
      y: minLeftTop.y * y
    }

    ctx.drawImage(
      imgRef.current,
      leftTop.x,
      leftTop.y,
      zoomedSize.x,
      zoomedSize.y,
    );

    setZoomProperties({
      x: -leftTop.x,
      y: -leftTop.y,
      width: zoomedSize.x,
      height: zoomedSize.y,
    });
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (!imgRef.current) {
      return;
    }

    const imgRect = imgRef.current.getBoundingClientRect();
    const xInPicture = (event.clientX - imgRect.left - imageDimensions.offsetX)
    const yInPicture = (event.clientY - imgRect.top - imageDimensions.offsetY)

    if (xInPicture < 0 || yInPicture < 0) {
      setIsZoomed(false);
      return;
    }

    if ((event.clientX > (imgRect.right - imageDimensions.offsetX)) || (event.clientY > (imgRect.bottom - imageDimensions.offsetY))) {
      setIsZoomed(false);
      return;
    }

    setIsZoomed(true);

    const xPercentage = xInPicture / (imgRect.width - imageDimensions.offsetX * 2)
    const yPercentage = yInPicture / (imgRect.height - imageDimensions.offsetY * 2)

    setCursorPosition({ x: xPercentage, y: yPercentage });
    drawZoomedArea(xPercentage, yPercentage);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScaleAndPosition);
    updateScaleAndPosition(); // Call initially to set scale

    return () => {
      window.removeEventListener("resize", updateScaleAndPosition);
    };
  }, [props.imageSrc]); // Re-run when image source changes

  const mmToPx = (mm: number) => (mm / 0.5) * scale; // Convert mm to scaled pixels

  const originalCoordToZoomed = (x: number, y: number) => {
    if (!canvasRef.current || !imgRef.current) {
      return { x: 0, y: 0 };
    }

    const staticCoord = {
      x: x * zoomFactor,
      y: zoomProperties.height - y * zoomFactor,
    }


    return staticCoord;
  }

  const handleClick = (e: React.MouseEvent) => {
    if (!isZoomed || !props.enableDrawing) {
      return;
    }


    const x = cursorPosition.x;
    const y = cursorPosition.y;

    if (!drawingMode) {
      setDrawingMode(true);
      setStartPoint({ x, y });
    } else {
      if (middlePoint === null) {
        setMiddlePoint({ x, y });
      } else if (middlePoint2 === null) {
        setMiddlePoint2({ x, y });
      } else {
        setEndPoint({ x, y });
        setMenuPosition({ x: e.clientX - 2, y: e.clientY - 4 });
        setDrawingMode(false);
      }
    }
  }

  const formatPoints = (positions: number[]) => {
    let points = "";
    let centroidX = 0;
    let centroidY = 0;
    let n = 0;
    for (let i = 2; i < positions.length; i += 2) {
      let x = mmToPx(positions[i]);
      let y = mmToPx(positions[i + 1]);

      if (isZoomed && canvasRef.current) {
        const transformed = originalCoordToZoomed(x, y);
        x = transformed.x;
        y = transformed.y;
      } else {
        y = imageDimensions.height - y;
      }

      points += `${x},${y} `;
      centroidX += x;
      centroidY += y;
      n++;
    }
    centroidX /= n;
    centroidY /= n;
    return { points: points.trim(), centroidX, centroidY };
  };

  const adjustPoint = (x: number, y: number) => {
    if (isZoomed && canvasRef.current) {
      const transformed = originalCoordToZoomed(mmToPx(x), mmToPx(y));
      return {
        x: transformed.x,
        y: transformed.y,
      }
    } else {
      return {
        x: mmToPx(x),
        y: imageDimensions.height - mmToPx(y)
      }
    };
  }

  const drawMortar = (brick: any, scale: number) => {
    const segments = [];
    const positions = brick.position;
    const thickness = isZoomed ? mortar_zoomed_width : mortar_normal_width;
    const inset = thickness * (isZoomed ? 5 : 2.5);

    if (
      brick.mortar_detected[brickBit.left[0]] ||
      brick.mortar_removed[brickBit.left[0]]
    ) {
      let start = adjustPoint(positions[brickPoints.top_left[0]], positions[brickPoints.top_left[1]]);
      let end = adjustPoint(positions[brickPoints.bottom_left[0]], positions[brickPoints.bottom_left[1]]);

      start.x += inset;
      start.y += inset;
      end.x += inset;
      end.y -= inset;
      if (!isNaN(start.x) && !isNaN(start.y) && !isNaN(end.x) && !isNaN(end.y)) {
        segments.push(
          <line
            x1={start.x}
            y1={start.y}
            x2={end.x}
            y2={end.y}
            stroke={
              brick.mortar_removed[brickBit.left[0]]
                ? mortar_removed_color
                : mortar_detected_color
            }
            strokeWidth={thickness}
            key={`mortar-side-${brickBit.left[0]}`}
          />
        );
      }
    }

    if (
      brick.mortar_detected[brickBit.right[0]] ||
      brick.mortar_removed[brickBit.right[0]]
    ) {
      let start = adjustPoint(positions[brickPoints.top_right[0]], positions[brickPoints.top_right[1]]);
      let end = adjustPoint(positions[brickPoints.bottom_right[0]], positions[brickPoints.bottom_right[1]]);

      start.x -= inset;
      start.y += inset;
      end.x -= inset;
      end.y -= inset;
      if (!isNaN(start.x) && !isNaN(start.y) && !isNaN(end.x) && !isNaN(end.y)) {
        segments.push(
          <line
            x1={start.x}
            y1={start.y}
            x2={end.x}
            y2={end.y}
            stroke={
              brick.mortar_removed[brickBit.right[0]]
                ? mortar_removed_color
                : mortar_detected_color
            }
            strokeWidth={thickness}
            key={`mortar-side-${brickBit.right[0]}`}
          />
        );
      }
    }

    for (let i = brickBit.top_segments[0]; i <= brickBit.top_segments[1]; i++) {
      let start = adjustPoint(positions[brickPoints.top_left[0]], positions[brickPoints.top_left[1]]);
      let end = adjustPoint(positions[brickPoints.top_right[0]], positions[brickPoints.top_right[1]]);

      start.x += inset;
      end.x -= inset;
      start.y += inset;
      end.y += inset;

      let segment_width = (end.x - start.x) / 8;

      if (brick.mortar_detected[i] || brick.mortar_removed[i]) {
        let segment_start = {
          x: start.x + segment_width * (i - brickBit.top_segments[0]),
          y: start.y,
        };
        let segment_end = { x: segment_start.x + segment_width, y: start.y };
        if (!isNaN(segment_start.x) && !isNaN(segment_start.y) && !isNaN(segment_end.x) && !isNaN(segment_end.y)) {
          segments.push(
            <line
              x1={segment_start.x}
              y1={segment_start.y}
              x2={segment_end.x}
              y2={segment_end.y}
              stroke={
                brick.mortar_removed[i]
                  ? mortar_removed_color
                  : mortar_detected_color
              }
              strokeWidth={thickness}
              key={`mortar-top-${i}`}
            />
          );
        }
      }
    }

    for (
      let i = brickBit.bottom_segments[0];
      i <= brickBit.bottom_segments[1];
      i++
    ) {
      let start = adjustPoint(positions[brickPoints.bottom_left[0]], positions[brickPoints.bottom_left[1]]);
      let end = adjustPoint(positions[brickPoints.bottom_right[0]], positions[brickPoints.bottom_right[1]]);

      start.x += inset;
      end.x -= inset;
      start.y -= inset;
      end.y -= inset;

      let segment_width = (end.x - start.x) / 8;

      if (brick.mortar_detected[i] || brick.mortar_removed[i]) {
        let segment_start = {
          x: start.x + segment_width * (i - brickBit.bottom_segments[0]),
          y: start.y,
        };
        let segment_end = { x: segment_start.x + segment_width, y: start.y };
        if (!isNaN(segment_start.x) && !isNaN(segment_start.y) && !isNaN(segment_end.x) && !isNaN(segment_end.y)) {
          segments.push(
            <line
              x1={segment_start.x}
              y1={segment_start.y}
              x2={segment_end.x}
              y2={segment_end.y}
              stroke={
                brick.mortar_removed[i]
                  ? mortar_removed_color
                  : mortar_detected_color
              }
              strokeWidth={thickness}
              key={`mortar-bottom-${i}`}
            />
          );
        }
      }
    }

    return segments;
  };

  React.useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      if (drawingMode) {
        resetDrawing();
      }
    };

    if (imgRef.current) {
      imgRef.current.addEventListener("contextmenu", handleContextMenu);
    }

    return () => {
      if (imgRef.current) {
        imgRef.current.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, [drawingMode]);


  const resetDrawing = () => {
    setDrawingMode(false);
    setStartPoint(null);
    setMiddlePoint(null);
    setMiddlePoint2(null);
    setEndPoint(null);
  }

  const imagePosition = (position: { x: number, y: number }, zoomed: boolean) => {
    if (zoomed) {
      return {
        x: position.x * zoomProperties.width,
        y: position.y * zoomProperties.height,
      }
    } else {
      return {
        x: position.x * imageDimensions.width,
        y: position.y * imageDimensions.height,
      }
    }
  }

  const drawUserInput = () => {
    if (startPoint === null) {
      return;
    }
    let stroke = 3;
    if (!isZoomed) {
      stroke = 1;
    }
    const graphics = [];

    let position = imagePosition(startPoint, isZoomed);
    graphics.push(<circle cx={position.x} cy={position.y} r={stroke} fill="blue" key={"start"} />);

    if (middlePoint !== null) {
      position = imagePosition(middlePoint, isZoomed);
      graphics.push(<circle cx={position.x} cy={position.y} r={stroke} fill="blue" key={"middle"} />);
    }

    if (middlePoint2 !== null) {
      position = imagePosition(middlePoint2, isZoomed);
      graphics.push(<circle cx={position.x} cy={position.y} r={stroke} fill="blue" key={"middle2"} />);
    }


    if (endPoint !== null) {
      position = imagePosition(endPoint, isZoomed);
      graphics.push(<circle cx={position.x} cy={position.y} r={stroke} fill="blue" key={"end"} />);
    }


    if (endPoint === null) {
      position = imagePosition(cursorPosition, isZoomed);
      graphics.push(<circle cx={position.x} cy={position.y} r={stroke} fill="blue" key={"cursor"} />);
    }

    if (graphics.length > 1) {
      const points = graphics.map((g) => {
        return `${g.props.cx},${g.props.cy}`;
      });
      const polygon = <polygon points={points.join(" ")} fill="limegreen" stroke="red" strokeWidth="0.5" fillOpacity="0.02" key={"polygon"} />;
      graphics.push(polygon);
    }


    return graphics;
  }

  const handleClose = () => {
    setMenuPosition(null);
    resetDrawing();
  }

// Function to create arrow points
const createArrowPoints = (x: number, y: number, angle: number, size: number) => {
  const radians = (angle * Math.PI) / 180;
  const arrowLength = size * 3; // Increase the length for a more distinct arrow
  const arrowWidth = size;
  const tailWidth = size * 0.5; // Make the tail narrower

  // Define the points for the arrow
  const x1 = x + arrowLength * Math.cos(radians);
  const y1 = y + arrowLength * Math.sin(radians);

  const x2 = x + arrowWidth * Math.cos(radians + Math.PI / 2);
  const y2 = y + arrowWidth * Math.sin(radians + Math.PI / 2);

  const x3 = x + arrowWidth * Math.cos(radians - Math.PI / 2);
  const y3 = y + arrowWidth * Math.sin(radians - Math.PI / 2);

  const x4 = x - arrowLength * 0.5 * Math.cos(radians);
  const y4 = y - arrowLength * 0.5 * Math.sin(radians);

  const x5 = x + tailWidth * Math.cos(radians + Math.PI / 2);
  const y5 = y + tailWidth * Math.sin(radians + Math.PI / 2);

  const x6 = x + tailWidth * Math.cos(radians - Math.PI / 2);
  const y6 = y + tailWidth * Math.sin(radians - Math.PI / 2);

  return `${x2},${y2} ${x1},${y1} ${x3},${y3} ${x6},${y6} ${x4},${y4} ${x5},${y5}`;
};

  return (
    <div style={{
      position: "relative",
      width: "100%",
      height: "48%",
      overflow: "hidden",
    }}>
      <img
        ref={imgRef}
        src={props.imageSrc}
        alt="Wall"
        style={{ width: "100%", height: "100%", objectFit: "contain", cursor: "crosshair" }}
        onLoad={updateScaleAndPosition}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setIsZoomed(false)}
        onClick={handleClick}
      />
      {isZoomed && (
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            left: imageDimensions.offsetX,
            top: imageDimensions.offsetY,
            width: imageDimensions.width,
            height: imageDimensions.height,
            pointerEvents: 'none',
            zIndex: 999,
          }}
        />
      )}
      <svg
        style={{
          position: "absolute",
          left: imageDimensions.offsetX - (!isZoomed ? 0 : zoomProperties.x),
          top: imageDimensions.offsetY - (!isZoomed ? 0 : zoomProperties.y),
          width: (!isZoomed ? "100%" : zoomProperties.width),
          height: (!isZoomed ? "100%" : zoomProperties.height),
          pointerEvents: "none",
          visibility: "visible",
          zIndex: 1000,
        }}
      >
        <defs>
          <clipPath id="clip">
            <rect x={!isZoomed ? "0" : zoomProperties.x} y={!isZoomed ? "0" : zoomProperties.y} width={imageDimensions.width} height={imageDimensions.height} />
          </clipPath>
        </defs>
        {props.wall &&
          props.wall.bricks.map((brick: any, index: number) => {
            const { points, centroidX, centroidY } = formatPoints(
              brick.position
            );
            return (
              <g clipPath="url(#clip)" key={"brick-" + index}>
                <polygon
                  points={points}
                  fill="none"
                  stroke={brick_color}
                  strokeWidth={isZoomed ? brick_zoomed_width : brick_normal_width}
                  strokeOpacity="0.4"
                  key={index}
                  strokeDasharray={isZoomed ? brick_zoomed_dasharray : brick_normal_dasharray}
                />
                {drawMortar(brick, scale)}
                <text
                  x={centroidX}
                  y={centroidY}
                  fill={text_color}
                  fontSize={isZoomed ? zoomed_text_size : normal_text_size}
                  textAnchor="middle"
                  alignmentBaseline="central"
                  key={`text-${index}`}
                >
                  {brick.id}({brick.grid_coordinates[0]},{" "}
                  {brick.grid_coordinates[1]})
                </text>
                {(drawingMode || menuPosition !== null) && drawUserInput()}
              </g>
            );
          })
        }
        {props.path.completed_path &&
          props.path.completed_path.map((point: any, index: number) => {
            if(index === props.path.completed_path.length - 1) {
              return null;
            }
            const adjustedPoint = adjustPoint(point.x, point.y);
            const arrowPoints = createArrowPoints(adjustedPoint.x, adjustedPoint.y, point.angle, isZoomed ? path_zoomed_radius : path_normal_radius);

            return (
              <g clipPath="url(#clip)" key={"completed-" + index}>
                 <polygon
                    points={arrowPoints}
                    fill={completed_path_color}
                    key={index}
                  />
                  </g>
            );
          })
        }

        { props.path.planned_path &&
          props.path.planned_path.map((point: any, index: number) => {
            const adjustedPoint = adjustPoint(point.x, point.y);
            const arrowPoints = createArrowPoints(adjustedPoint.x, adjustedPoint.y, point.angle, isZoomed ? path_zoomed_radius : path_normal_radius);

            if (index === props.path.planned_path.length - 1) {
              point = (
                <circle
                  cx={adjustPoint(point.x, point.y).x}
                  cy={adjustPoint(point.x, point.y).y}
                  r={isZoomed ? current_zoomed_radius : current_normal_radius}
                  fill={planned_path_color}
                  key={index}
                />
              );
            } else {
              point = (
                <polygon
                  points={arrowPoints}
                  fill={planned_path_color}
                  key={index}
                />
              );
            }
            return (
              <g clipPath="url(#clip)" key={"planned-" + index}>
                {point}
              </g>
            );
          })
        }
        {props.path.current_position && 
          <g clipPath="url(#clip)" key={"position"}>
            <circle
              cx={adjustPoint(props.path.current_position.x, props.path.current_position.y).x}
              cy={adjustPoint(props.path.current_position.x, props.path.current_position.y).y}
              r={isZoomed ? current_zoomed_radius : current_normal_radius}
              fill={current_position_color}
            />
          </g>
        }
        {props.path.tool_current &&
          <g clipPath="url(#clip)" key={"current-toolbox"}>
            <polygon
              points={formatPoints([0, 0, ...props.path.tool_current]).points}
              fill="none"
              stroke={current_position_color}
              strokeWidth="1"
              strokeOpacity="0.9"
            />
          </g>
        }
        {props.path.tool_target &&
          <g clipPath="url(#clip)" key={"target-toolbox"}>
            <polygon
              points={formatPoints([0, 0, ...props.path.tool_target]).points}
              fill="none"
              stroke={planned_path_color}
              strokeWidth="1"
              strokeOpacity="0.9"
            />
          </g>
        }
      </svg>
      <Menu
        id="add-brick-menu"
        open={menuPosition !== null}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ left: menuPosition == null ? 0 : menuPosition.x, top: menuPosition == null ? 0 : menuPosition.y }}
      >
        <MenuItem onClick={() => addBrickPosition(-1)}>Add new</MenuItem>

        <SubMenu text="Set position" options={props.wall ? props.wall.bricks.map((brick: any) => brick.id.toString()) : []} selectFunction={(a: string) => addBrickPosition(parseInt(a))} />
      </Menu>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={severity !== null && message !== null}
        onClose={() => { setSeverity(null); setMessage(null); }}
        autoHideDuration={4000}
      >
        <Alert onClose={() => { setSeverity(null); setMessage(null); }} severity={(severity || "info") as any} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BrickOverlay;
