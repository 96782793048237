import * as React from "react";
import {Box, LinearProgress, Typography} from "@mui/material";


export default function UnsignedLayout(props: any) {
    return (
        <Box>
            <LinearProgress/>

            <Typography
                align="center"
                variant="h6"
                component="div"
                sx={{flexGrow: 1, margin: 8}}>Loading...</Typography>
        </Box>
    );
}