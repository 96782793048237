import * as React from "react";
import {useEffect, useReducer, useState} from "react";

import {auth} from '../utils/firebase'
import {onAuthStateChanged} from "firebase/auth"

import {Box, Drawer, Tab} from '@mui/material';
import SignedHeader from "../components/SignedHeader";
import SideDrawer from '../components/SideDrawer'
import KPIControlTab from '../components/kpi/KPIControlTab'
import KPITableTab from '../components/kpi/KPITableTab'
import KPIGraphTab from '../components/kpi/KPIGraphTab'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import {compareRunIds, sortObj} from "../utils/utils"
import logo from "../images/logo.png"
import UnsignedLayout from "../components/UnsignedLayout";


const headers = ["Brick identification",
    "Visual odometry",
    "Visual odometry and find bricks",
    "Post processing",
    "Path planning",
    "Last part",
    "Whole step",
    "Whole step - Last part",
    "Spindle speed",
    "Milling depth",
    "Loop counter",
    "Total time [s]",
    "Sides of mortar milled",
    "Sides of mortar milled found from brick sizes",
    "Top & bots half bricks milled",
    "Top & bots three quarters bricks milled",
    "Top & bots whole bricks milled",
    "Total distance found bricks [mm]",
    "Total distance based on a standard brick [mm]",
    "Speed [mm/s]",
    "Speed std brick [mm/s]",
    "Speed [m2/h]",
    "Speed std brick [m2/h]",
    "Found half bricks",
    "Found three quarters bricks",
    "Found whole bricks",
    "Found half bricks percent",
    "Found three quarters bricks percent",
    "Found whole bricks percent",
    "Mortar around half brick [mm]",
    "Mortar around three quarters brick [mm]",
    "Mortar around whole brick [mm]",
    "Starting layer",
    "Total bricks in working area",
    "Total found bricks based on shift",
    "Total found bricks",
    "Removed of total working area percent",
    "Removed of started shifts",
    "Amps used by spindle motor when above mean",
    "25 procentile of spindle motor amps",
    "50 procentile of spindle motor amps",
    "75 procentile of spindle motor amps",
    "95 procentile of spindle motor amps",
    "Error path planning count",
    "Error count arduino",
    "Communication error count VFD",
    "CPU above limit count",
    "CPU python above limit count",
    "RAM above limit count",
    "Max speed",
    "Fast speed",
    "Slow speed",
    "Boring speed",
    "Number of damaged bricks",
    "Number of estimated bricks"];

export default function KPIPage(props: { name: string, apiUrl: string }) {
    const [drawerOpened, openDrawer] = useState(false);
    const [tab, changeTab] = useState("1");
    const [userName, changeUserName] = useState("");
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user && auth.currentUser) {
                let name = auth.currentUser.displayName;
                if (name === undefined || name === null) {
                    name = auth.currentUser.email!.split("@")[0];
                }
                changeUserName(name);
            } else {
                window.location.replace('/');
            }
        });
    }, []);

    const [calendarData, changeCalendarData] = useState<{ from: string, to: string, frequency: Array<{ day: string, value: number }> } | undefined>(undefined);
    useEffect(() => {
        const url = props.apiUrl + "/serialized/search?extensions=csv&dataTypes=final"
        console.log(url);
        fetch(url, {method: "GET"}).then(response => {
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            return response.json();
        }).then((json: Array<any>) => {
            const dates = json.map(r => {
                return r.metadata.takenAt.split("T")[0]
            });
            let count: { [id: string]: number; };
            count = {}
            dates.forEach(e => count[e] ? count[e]++ : count[e] = 1);
            const sortedDates = Object.keys(count).sort();
            const frequency = [];
            for (const [key, value] of Object.entries(count)) {
                frequency.push({"day": key, "value": value});
            }
            changeCalendarData({
                from: sortedDates[0],
                to: sortedDates[sortedDates.length - 1],
                frequency: frequency
            });
        });
    }, [props.apiUrl]);

    const [graphEnabled, graphEnabledChange] = useReducer((graphEnabled: Array<string>, value: string) => {
        if (graphEnabled.indexOf(value) !== -1) {
            return graphEnabled.filter((v) => v !== value);
        } else {
            return [...graphEnabled, value];
        }
    }, ["Loop counter", "Total bricks in working area"]);

    const [tableEnabled, tableEnabledChange] = useReducer((tableEnabled: Array<string>, value: string) => {
        if (tableEnabled.indexOf(value) !== -1) {
            return tableEnabled.filter((v) => v !== value);
        } else {
            return [...tableEnabled, value];
        }
    }, ["Loop counter", "Sides of mortar milled", "Whole step", "Total bricks in working area", "Speed [mm/s]"]);

    const handleRemove = (runIds: Array<string>) => {
        dispatchRunKPIsChange({type: "remove", toDelete: runIds});
    }

    const handleAdd = (runIds: Array<string>) => {
        let url = props.apiUrl + "/serialized/runOverview?"
        let newRun = false;

        runIds.forEach(currentItem => {
            if (currentItem in runKPIs) return;
            newRun = true;
            url += "runId=" + currentItem + "&";
        });

        if (newRun) {
            url = url.substring(0, url.length - 1);
            fetch(url, {method: 'GET'})
                .then(response => {
                    if (!response.ok) {
                        throw new Error("HTTP error " + response.status);
                    }
                    return response.json();
                })
                .then(json => {
                    dispatchRunKPIsChange({type: "add", toAdd: json});
                });
        }
    }

    const runKPIsReducer = (state: { [runId: string]: { [indicator: string]: number } }, action: any) => {
        switch (action.type) {
            case "remove":
                return Object.keys(state).reduce((result: { [runId: string]: { [indicator: string]: number } }, key: string) => {
                    if (action.toDelete.indexOf(key) === -1) {
                        result[key] = state[key];
                    }
                    return result;
                }, {});
            case "add":
                return sortObj({...action.toAdd, ...state}, compareRunIds, false);
            default:
                return state;
        }
    };
    const [runKPIs, dispatchRunKPIsChange] = useReducer(runKPIsReducer, {});

    return userName === "" ? <UnsignedLayout/> : (
        <Box sx={{display: "flex", flexFlow: "column", height: "100%"}}>
            <SignedHeader
                name={props.name}
                icon={logo}
                openDrawer={() => openDrawer(true)}/>
            <Box sx={{width: '100%', typography: 'body1'}}>
                <TabContext value={tab}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={(e: any, v: string) => changeTab(v)} aria-label="table visualization tabs"
                                 variant="fullWidth">
                            <Tab label="Control" value="1"/>
                            <Tab label="Table" value="2"/>
                            <Tab label="Graph" value="3"/>
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <KPIControlTab
                            onAdd={(runs) => handleAdd(runs)}
                            onRemove={(runs) => handleRemove(runs)}
                            apiUrl={props.apiUrl}
                            calendarData={calendarData}
                            runs={Object.keys(runKPIs)}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <KPITableTab
                            properties={headers}
                            enabled={tableEnabled}
                            onPropertiesChange={tableEnabledChange}
                            runs={runKPIs}/>
                    </TabPanel>
                    <TabPanel value="3">
                        <KPIGraphTab
                            properties={headers}
                            enabled={graphEnabled}
                            runs={runKPIs}
                            onPropertiesChange={graphEnabledChange}/>
                    </TabPanel>
                </TabContext>
            </Box>
            <Drawer
                anchor="left"
                open={drawerOpened}
                onClose={() => openDrawer(false)}
            >
                <SideDrawer
                    closeDrawer={() => openDrawer(false)}
                    user={userName}
                />
            </Drawer>
        </Box>
    );
}