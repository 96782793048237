import React, { useState, useEffect, useRef } from "react";
import ROSLIB from "roslib";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Fab,
  Stack,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "./LogViewer.css"; // Assume CSS is defined in a separate file
import { TabPanel } from "@mui/lab";
import { log } from "console";

const LogViewer = (props: { ros: ROSLIB.Ros | null }) => {
  const historySize = 10000;
  const [logs, setLogs] = useState<ROSLIB.Message[]>([]);
  const [logLevel, setLogLevel] = useState<
    "DEBUG" | "INFO" | "WARN" | "ERROR" | "FATAL"
  >("INFO");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const logContainerRef = useRef<HTMLDivElement>(null);
  const [allClasses, setAllClasses] = useState<string[]>(["any"]);
  const [logSource, setLogSource] = useState<string>("any");

  const logLevels = {
    DEBUG: 10,
    INFO: 20,
    WARN: 30,
    ERROR: 40,
    FATAL: 50,
  };

  useEffect(() => {
    if (!props.ros) {
      return;
    }

    const listener = new ROSLIB.Topic({
      ros: props.ros,
      name: "/rosout",
      messageType: "rcl_interfaces/msg/Log",
      compression: "none",
    });

    listener.subscribe((message: any) => {
      setAllClasses(prevClasses => {
        if (prevClasses.indexOf(message.name) === -1) {
          return [...prevClasses, message.name];
        } else {
          return prevClasses;
        }
      });

      setLogs((prevLogs: ROSLIB.Message[]) => {
        if (logContainerRef.current) {
          const isScrolledToBottom =
            logContainerRef.current.scrollHeight -
            logContainerRef.current.clientHeight <=
            logContainerRef.current.scrollTop + 1;
          setTimeout(() => {
            if (isScrolledToBottom) {
              logContainerRef.current?.scrollTo(
                0,
                logContainerRef.current.scrollHeight
              );
            } else if (!showScrollButton) {
              setShowScrollButton(true);
            }
          }, 100);
        }
        return [...prevLogs.slice(-historySize), message];
      });
    });

    return () => {
      listener.unsubscribe();
    };
  }, [props.ros]);

  const scrollToBottom = () => {
    logContainerRef.current?.scrollTo({
      top: logContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
    setShowScrollButton(false);
  };

  const handleScroll = () => {
    const isScrolledToBottom =
      logContainerRef.current!.scrollHeight -
      logContainerRef.current!.clientHeight <=
      logContainerRef.current!.scrollTop + 1;
    setShowScrollButton(!isScrolledToBottom);
  };

  const filteredLogs = logs.filter(
    (log: any & { level: number }) => (log.level >= logLevels[logLevel]) && (logSource === "any" || log.name === logSource)
  );

  const renderSingleLog = (log: any, index: number) => {
    const className = Object.keys(logLevels).find(
      (key) => logLevels[key as keyof typeof logLevels] === log.level
    );
    const time =
      new Date(log.stamp.sec * 1000).toTimeString().split(" ")[0] +
      "." +
      log.stamp.nanosec.toString().slice(0, 3);

    return (
      <div key={index} className={`log-message ${className?.toLowerCase()}`}>
        [{className}] [{time}][{log.name}] {log.msg}
      </div>
    );
  };

  return (
    <TabPanel
      value="0"
      sx={{
        maxHeight: "100%", margin: "0px"
        ,width: "100%",
        flex: 1, padding: "5px", paddingTop: "20px"
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          padding: "5px",
        }}
      >
        <FormControl fullWidth sx={{ maxHeight: "20%" }}>
          <InputLabel id="log-level-label">Log Level</InputLabel>
          <Select
            labelId="log-level-label"
            value={logLevel}
            label="Log Level"
            onChange={(e) =>
              setLogLevel(
                e.target.value as "DEBUG" | "INFO" | "WARN" | "ERROR" | "FATAL"
              )
            }
          >
            {Object.keys(logLevels).map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ maxHeight: "20%" }}>
          <InputLabel id="log-source-label">Log Source</InputLabel>
          <Select
            labelId="log-source-label"
            value={logSource}
            label="Log Source"
            onChange={(e) =>
              setLogSource(e.target.value as string)
            }
          >
            {allClasses.map((source) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          className="log-viewer"
          ref={logContainerRef}
          onScroll={handleScroll}
        >
          {filteredLogs.map((log: any, index) => renderSingleLog(log, index))}
        </div>
        {showScrollButton && (
          <Fab
            color="primary"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            onClick={scrollToBottom}
          >
            <ArrowDownwardIcon />
          </Fab>
        )}
      </Stack>
    </TabPanel>
  );
};

export default LogViewer;
