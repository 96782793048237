import * as React from "react";

import {Box, Button, CircularProgress, Stack, Typography} from '@mui/material';
import RunCard from "../RunCard";
import {compareRunIds, sortObj} from "../../utils/utils"


interface FeedTabState {
    from: Date,
    runsInFeed: Array<string>,
    fetching: boolean,

    runDetails: { [runId: string]: { [indicator: string]: number } },

    runImages: { [runId: string]: Array<string> }
}

class FeedTab extends React.Component<{ apiUrl: string }, FeedTabState> {

    constructor(props: { apiUrl: string }) {
        super(props as any);
        const endDate = new Date();
        const daySpan = 60 * 60 * 24 * 1000;
        const startDate = new Date(endDate.getTime() - daySpan);
        this.state = {
            from: startDate,
            runsInFeed: [],
            runDetails: {},
            runImages: {},
            fetching: true
        }

        this.queryRunsInFeed(this.state.from);
    }

    queryRunsInFeed(startDate: Date) {
        const query = this.props.apiUrl + "/serialized/search?extensions=csv&dataTypes=final&from=" + startDate.toISOString();
        fetch(query, {method: "GET"})
            .then(response => {
                if (!response.ok) {
                    this.setState({fetching: false});
                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            })
            .then((json: Array<any>) => {
                if (json.length > 0) {
                    this.setState({
                            runsInFeed: json.map(r => r.metadata.runId).filter(r => (r.split("_").length - 1) > 4).sort((a, b) => compareRunIds(b, a))
                        }
                        , () => this.fetchRunData(this.state.runsInFeed));
                } else {
                    this.loadMore();
                }
            });
    }

    fetchRunData(runs: Array<string>) {
        let url = this.props.apiUrl + "/serialized/runOverview?"
        let newRun = false;

        runs.forEach(currentItem => {
            if (currentItem in this.state.runDetails) return;
            newRun = true;
            url += "runId=" + currentItem + "&";
        });

        if (newRun) {
            url = url.substring(0, url.length - 1);
            fetch(url, {method: 'GET'})
                .then(response => {
                    if (!response.ok) {
                        this.setState({fetching: false});
                        throw new Error("HTTP error " + response.status);
                    }
                    return response.json();
                })
                .then(json => {
                    json = sortObj(json, compareRunIds, true);

                    this.setState((prevState) => {
                            return {runDetails: {...prevState.runDetails, ...json}}
                        },
                        () => {
                            this.setState({fetching: false});
                            this.fetchImages(Object.keys(this.state.runDetails));
                        });
                });
        } else {
            this.setState({fetching: false});
        }
    }

    sortImages(a: { imageType: string, picNum: number }, b: { imageType: string, picNum: number }) {
        if (a.imageType === "qa" && b.imageType === "full") {
            return -10;
        } else if (b.imageType === "qa" && a.imageType === "full") {
            return 10;
        }

        return a.picNum - b.picNum;
    }

    fetchImages(runs: Array<string>) {
        for (let r in runs) {
            if (this.state.runImages[runs[r]] !== undefined) continue;
            let imagesUrl = this.props.apiUrl + "/images/search?runId=" + runs[r] + "&imageType=qa&imageType=full"
            fetch(imagesUrl, {method: 'POST'}).then(response => {
                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            })
                .then(json => {
                    this.setState((prevState) => {
                        let newState = prevState.runImages;
                        newState[runs[r]] = json.sort((a: any, b: any) => this.sortImages(a.metadata, b.metadata)).map((o: any) => o.url);
                        return {runImages: newState};
                    });
                });
        }
    }

    loadMore() {
        const endDate = this.state.from;
        const daySpan = 60 * 60 * 24 * 1000;
        const startDate = new Date(endDate.getTime() - daySpan);
        this.setState({fetching: true, from: startDate}, () => this.queryRunsInFeed(this.state.from));
    }

    render() {
        if (Object.keys(this.state.runDetails).length < 1) {
            return (
                <Stack direction="column" spacing={2}
                       sx={{width: "100%", alignItems: "center", justifyContent: "center"}}>
                    <Typography variant="h6">Fetching runs...</Typography>
                    <CircularProgress/>
                </Stack>);
        } else {
            return (
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start"
                }}>
                    {Object.keys(this.state.runDetails).map(k =>
                        <RunCard
                            key={k}
                            runId={k}
                            data={this.state.runDetails[k]}
                            images={this.state.runImages[k]}
                            apiUrl={this.props.apiUrl}
                        />)}
                    <Button
                        disabled={this.state.fetching}
                        onClick={() => this.loadMore()}
                        sx={{width: "100%", height: 100}}>
                        Load more
                        {this.state.fetching && (
                            <CircularProgress
                                size={28}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>
                </Box>);
        }
    }
}

export default FeedTab;