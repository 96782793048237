import * as React from "react";
import { Box } from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import SensorsIcon from "@mui/icons-material/Sensors";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

export default function Status(props: {
  connected: boolean;
  running: boolean;
}) {
  var color = "#b2102f";
  var message = "No robot available";
  var icon = <DangerousIcon />;

  if (props.connected && props.running) {
    color = "#52b202";
    message = "Robot is running";
    icon = <PrecisionManufacturingIcon />;
  } else if (props.connected && !props.running) {
    color = "#5393ff";
    message = "Robot is connected";
    icon = <SensorsIcon />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: color,
        padding: "5px",
      }}
    >
      {icon}
      {message}
    </Box>
  );
}
