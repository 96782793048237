import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";


export default function DayDialog(props: { desc: string, open: boolean, onClose: (day: string | null) => void }) {
    const [pickedDay, changePickedDay] = useState<string>("2023-02-28");
    return (
        <Dialog open={props.open} onClose={() => props.onClose(null)}>
            <DialogTitle>Select a date</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.desc}
                </DialogContentText>
                <TextField
                    id="date"
                    label="Day"
                    type="date"
                    value={pickedDay}
                    autoFocus={true}
                    sx={{width: 220, margin: 2}}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e: any) => changePickedDay(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(null)}>Cancel</Button>
                <Button onClick={() => props.onClose(pickedDay)}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}