import { Button, Grid, Typography, Stack } from "@mui/material";
import * as React from "react";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import { useEffect, useState } from "react";
import ParamField from "./ParamField";
import { TabPanel } from "@mui/lab";
import ROSLIB from "roslib";
import IosShareIcon from '@mui/icons-material/IosShare';

export default function MillingSetup(props: {
  enableEdit: boolean;
  ros: ROSLIB.Ros | null,
}) {
  const [areaWidth, changeAreaWidth] = useState<number | null>(null);
  const [areaHeight, changeAreaHeight] = useState<number | null>(null);
  const [movementSpeed, changeMovementSpeed] = useState<number | null>(null);
  const [boringSpeed, changeBoringSpeed] = useState<number | null>(null);
  
  const [drillingSpeed, changeDrillingSpeed] = useState<number | null>(null);
  const [currentSpeed, changeCurrentSpeed] = useState<number | null>(null);

  const [distanceToWall, changeDistanceToWall] = useState<number | null>(null);
  const [depthDrilling, changeDepthDrilling] = useState<number | null>(null);
  const [discStartAngle, changeDiscStartAngle] = useState<number | null>(null);
  const [millToCamera, changeMillToCamera] = useState<Array<
    number | null
  > | null>(null);
  const [horizontalMargin, changeHorizontalMargin] = useState<number | null>(null);
  const [verticalMargin, changeVerticalMargin] = useState<Array<
    number | null
  > | null>(null);
  const [discWearDepth, changeDiscWearDepth] = useState<number | null>(null);
  const [discWearWidth, changeDiscWearWidth] = useState<number | null>(null);
  const [startLayer, changeStartLayer] = useState<number | null>(null);
  const [endLayer, changeEndLayer] = useState<number | null>(null);

  const [ppCurrentData, changePPCurrentData] = useState<any | null>(null);
  const [controllerCurrentData, changeControllerCurrentData] = useState<any | null>(null);
  const [tfCurrentData, changeTfCurrentData] = useState<any | null>(null);

  const [severity, setSeverity] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!props.ros) {
      return;
    }

    const ppConfigListener = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/pp_config",
      messageType: "sitetech_interface/msg/PPConfig",
    });

    ppConfigListener.subscribe((message: any) => {
      changePPCurrentData(message);
    });

    const controllerConfigListener = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/controller_config",
      messageType: "sitetech_interface/msg/ControllerConfig",
    });

    controllerConfigListener.subscribe((message: any) => {
      changeControllerCurrentData(message);
    });

    const tfConfigListener = new ROSLIB.Topic({
      ros: props.ros,
      name: "/remote/tf_config",
      messageType: "sitetech_interface/msg/TFConfig",
    });

    tfConfigListener.subscribe((message: any) => {
      console.log(message);
      changeTfCurrentData(message);
    });

    return () => {
      ppConfigListener.unsubscribe();
      controllerConfigListener.unsubscribe();
      tfConfigListener.unsubscribe();
    };
  }, [props.ros]);

  useEffect(() => {
    if (ppCurrentData !== null) {
      if (distanceToWall === null || !props.enableEdit) {
        changeDistanceToWall(ppCurrentData.distance_to_wall);
      }

      if (depthDrilling === null || !props.enableEdit) {
        changeDepthDrilling(ppCurrentData.depth_drilling);
      }

      if (discWearDepth === null || !props.enableEdit) {
        changeDiscWearDepth(ppCurrentData.disc_wear_depth);
      }

      if (discWearWidth === null || !props.enableEdit) {
        changeDiscWearWidth(ppCurrentData.disc_wear_width);
      }

      if (startLayer === null || !props.enableEdit) {
        changeStartLayer(ppCurrentData.start_layer);
      }

      if (endLayer === null || !props.enableEdit) {
        changeEndLayer(ppCurrentData.end_layer);
      }

      if(movementSpeed === null || !props.enableEdit) {
        changeMovementSpeed(ppCurrentData.moving_speed);
      }

      if(boringSpeed === null || !props.enableEdit) {
        changeBoringSpeed(ppCurrentData.boring_down_speed);
      }

      if(drillingSpeed === null || !props.enableEdit) {
        changeDrillingSpeed(ppCurrentData.drilling_speed);
      }

      if(horizontalMargin === null || !props.enableEdit) {
        changeHorizontalMargin(ppCurrentData.horizontal_margin);
      }

      if(verticalMargin === null || !props.enableEdit) {
        changeVerticalMargin(ppCurrentData.vertical_margin);
      }
    }

    if(controllerCurrentData !== null) {
      if (currentSpeed === null || !props.enableEdit) {
        changeCurrentSpeed(controllerCurrentData.current_speed);
      }

      if (areaWidth === null || !props.enableEdit) {
        changeAreaWidth(controllerCurrentData.area_width);
      }

      if (areaHeight === null || !props.enableEdit) {
        changeAreaHeight(controllerCurrentData.area_height);
      }

      if (discStartAngle === null || !props.enableEdit) {
        changeDiscStartAngle(controllerCurrentData.start_angle);
      }
    }

    if(tfCurrentData !== null) {
      if (millToCamera === null || !props.enableEdit) {
        changeMillToCamera(tfCurrentData.mill_to_camera_center);
      }
    }
  }, [
    props.enableEdit,
    ppCurrentData,
    controllerCurrentData,
    tfCurrentData,
    distanceToWall,
    depthDrilling,
    discWearDepth,
    discWearWidth,
    startLayer,
    endLayer,
    movementSpeed,
    boringSpeed,
    drillingSpeed,
    horizontalMargin,
    verticalMargin,
    currentSpeed,
    areaWidth,
    areaHeight,
    discStartAngle,
    millToCamera,
  ]);

  const ppUpdated = () => {
    return (
      ppCurrentData !== null &&
      ppCurrentData.distance_to_wall === distanceToWall &&
      ppCurrentData.depth_drilling === depthDrilling &&
      ppCurrentData.disc_wear_depth === discWearDepth &&
      ppCurrentData.disc_wear_width === discWearWidth &&
      ppCurrentData.start_layer === startLayer &&
      ppCurrentData.end_layer === endLayer &&
      ppCurrentData.moving_speed === movementSpeed &&
      ppCurrentData.boring_down_speed === boringSpeed &&
      ppCurrentData.drilling_speed === drillingSpeed &&
      ppCurrentData.horizontal_margin === horizontalMargin &&
      verticalMargin !== null &&
      ppCurrentData.vertical_margin[0] === verticalMargin[0] &&
      ppCurrentData.vertical_margin[1] === verticalMargin[1]
    );
  };

  const controllerUpdated = () => {
    return (
      controllerCurrentData !== null &&
      controllerCurrentData.current_speed === currentSpeed &&
      controllerCurrentData.area_width === areaWidth &&
      controllerCurrentData.area_height === areaHeight &&
      controllerCurrentData.start_angle === discStartAngle
    );
  };

  const tfUpdated = () => {
    return (
      tfCurrentData !== null &&
      millToCamera !== null &&
      tfCurrentData.mill_to_camera_center[0] === millToCamera[0] &&
      tfCurrentData.mill_to_camera_center[1] === millToCamera[1]
    );
  }

  const sendCommand = () => {

    if (!props.ros) {
      return;
    }

    if(!ppUpdated()) {
      const ppConfigClient = new ROSLIB.Service({
        ros: props.ros,
        name: "/remote/pp_config_set",
        serviceType: "sitetech_interface/srv/PPConfigSet",
      });
      
      const request = new ROSLIB.ServiceRequest({
        pp_config: {
          distance_to_wall: distanceToWall,
          depth_drilling: depthDrilling,
          disc_wear_depth: discWearDepth,
          disc_wear_width: discWearWidth,
          start_layer: startLayer,
          end_layer: endLayer,
          moving_speed: movementSpeed,
          boring_down_speed: boringSpeed,
          drilling_speed: drillingSpeed,
          horizontal_margin: horizontalMargin,
          vertical_margin: verticalMargin
        },
      });

      ppConfigClient.callService(request, (result: any) => {
        console.log("Result: " + result);
        if (result.success) {
          setSeverity("success");
          setMessage("PP parameters updated successfully");
        } else {
          setSeverity("error");
          setMessage("Failed to update PP parameters");
        }
      });
    }

    if(!controllerUpdated()) {
      const controllerConfigClient = new ROSLIB.Service({
        ros: props.ros,
        name: "/remote/controller_config_set",
        serviceType: "sitetech_interface/srv/ControllerConfigSet",
      });

      const request = new ROSLIB.ServiceRequest({
        controller_config: {
          current_speed: currentSpeed,
          area_width: areaWidth,
          area_height: areaHeight,
          start_angle: discStartAngle,
        },
      });

      controllerConfigClient.callService(request, (result: any) => {
        console.log("Result: " + result);
        if (result.success) {
          setSeverity("success");
          setMessage("Controller parameters updated successfully");
        } else {
          setSeverity("error");
          setMessage("Failed to update controller parameters");
        }
      });
    }

      if (!tfUpdated()) {
        const tfConfigClient = new ROSLIB.Service({
          ros: props.ros,
          name: "/remote/tf_config_set",
          serviceType: "sitetech_interface/srv/TFConfigSet",
        });

        const request = new ROSLIB.ServiceRequest({
          tf_config: {
            mill_to_camera_center: millToCamera,
          },
        });

        tfConfigClient.callService(request, (result: any) => {
          console.log("Result: " + result);
          if (result.success) {
            setSeverity("success");
            setMessage("TF parameters updated successfully");
          } else {
            setSeverity("error");
            setMessage("Failed to update TF parameters");
          }
        });
      }
    }

  const upToDate = (ppUpdated() && controllerUpdated() && tfUpdated());

  return (
    <TabPanel
      value="1"
      sx={{
        height: "100%", 
        width: "100%", 
        margin: "0px",
        flex: 1,
        padding: "5px",
        paddingTop: "20px",
        display: "flex", // Ensure it behaves as a flex container
        flexDirection: "column", // Arrange children in a column
        overflow: "hidden", // Prevent overflow
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          padding: "5px",
        }}
      >
      <Grid
      container
        sx={{ width: "100%", height: "100%", overflow: "auto", padding: "5px", 
          flexGrow: 1, // Ensure the Grid behaves flexibly within the container
          flexBasis: 0, // Prevent it from taking more space than necessary 
        }}
        spacing={{ xs: 1, md: 2 }}
        columns={2}
      >
        <Grid item xs={1} key="updated_status">
          {(upToDate || !props.enableEdit) && (
            <Typography
              variant="body1"
              sx={{ height: "100%", alignItems: "center", display: "flex" }}
            >
              All parameters in sync 🟢
            </Typography>
          )}
          {(props.enableEdit && !upToDate) && (
            <Typography
              variant="body1"
              sx={{
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              Parameters edited in GUI 🟡
            </Typography>
          )}
        </Grid>
        <Grid item xs={1} key="update_button">
          <Button
            variant="contained"
            startIcon={(upToDate || !props.enableEdit) ? undefined : <IosShareIcon />}
            disabled={upToDate || !props.enableEdit}
            onClick={() => sendCommand()}
          >
            {(upToDate || !props.enableEdit) ? "Updated" : "Send to robot"}
          </Button>
        </Grid>
        <Grid item xs={2} key="drilling">
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Drilling
          </Typography>
        </Grid> 
        <Grid item xs={1} key="depth_input">
          <ParamField
            name="Cut depth"
            disable={!props.enableEdit}
            changeValue={changeDepthDrilling}
            value={depthDrilling}
            helperText=" (mm, + deeper, - shallower)"
            currentData={
              ppCurrentData === null
                ? null
                : ppCurrentData.depth_drilling
            }
            negativeEnabled={true}
          />
        </Grid>
        <Grid item xs={1} key="z_tool_input">
          <ParamField
            name="Tool from wall"
            disable={!props.enableEdit}
            changeValue={changeDistanceToWall}
            value={distanceToWall}
            helperText=" (mm, + further, - closer)"
            currentData={
              ppCurrentData === null ? null : ppCurrentData.distance_to_wall
            }
          />
        </Grid> 
        <Grid item xs={1} key="angle_input">
          <ParamField
            name="Angle"
            disable={!props.enableEdit}
            changeValue={changeDiscStartAngle}
            value={discStartAngle}
            precision={1}
            helperText=" (°, +↺, -↻)"
            currentData={
              controllerCurrentData === null
                ? null
                : controllerCurrentData.start_angle
            }
            negativeEnabled={true}
          />
        </Grid>
        <Grid item xs={1} key="placeholder"></Grid>
        <Grid item xs={1} key="tool_wear_width">
          <ParamField
            name="Disc wear width"
            disable={!props.enableEdit}
            changeValue={changeDiscWearWidth}
            value={discWearWidth}
            precision={1}
            currentData={
              ppCurrentData === null
                ? null
                : ppCurrentData.disc_wear_width
            }
            negativeEnabled={true}
          />
        </Grid>
        <Grid item xs={1} key="tool_wear_depth">
          <ParamField
            name="Disc wear depth"
            disable={!props.enableEdit}
            changeValue={changeDiscWearDepth}
            value={discWearDepth}
            precision={1}
            currentData={
              ppCurrentData === null
                ? null
                : ppCurrentData.disc_wear_depth
            }
            negativeEnabled={true}
          />
        </Grid>
        <Grid item xs={1} key="horizontal_margin">
          <ParamField
            name="Horizontal margin"
            disable={!props.enableEdit}
            changeValue={changeHorizontalMargin}
            value={horizontalMargin}
            precision={1}
            currentData={
              ppCurrentData === null
                ? null
                : ppCurrentData.horizontal_margin
            }
            negativeEnabled={true}
            helperText={" (mm, + further, - closer)"}
          />
        </Grid>
        <Grid item xs={1} key="placeholder2"></Grid>
        
        <Grid item xs={1} key="vertical_margin_x">
          <ParamField
            name="Vertical margin X"
            disable={!props.enableEdit}
            changeValue={(e: number) => {
              if (verticalMargin !== null) {
                changeVerticalMargin([e, verticalMargin[1]]);
              }
            }}
            value={verticalMargin == null ? null : verticalMargin[0]}
            precision={1}
            currentData={
              ppCurrentData === null
                ? null
                : ppCurrentData.vertical_margin[0]
            }
            negativeEnabled={true}
            helperText={" (mm, + further, - closer)"}
          />
        </Grid>
        <Grid item xs={1} key="vertical_margin_y">
          <ParamField
            name="Vertical margin Y"
            disable={!props.enableEdit}
            changeValue={(e: number) => {
              if (verticalMargin !== null) {
                changeVerticalMargin([verticalMargin[0], e]);
              }
            }}
            value={verticalMargin == null ? null : verticalMargin[1]}
            precision={1}
            currentData={
              ppCurrentData === null
                ? null
                : ppCurrentData.vertical_margin[1]
            }
            negativeEnabled={true}
            helperText={" (mm, + further, - closer)"}
          />
        </Grid>
        <Grid item xs={2} key="speed">
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Speed
          </Typography>
        </Grid>
        <Grid item xs={1} key="movement_speed_input">
          <ParamField
            disable={!props.enableEdit}
            name="Movement speed"
            changeValue={changeMovementSpeed}
            value={movementSpeed}
            currentData={ppCurrentData === null ? null : ppCurrentData.moving_speed}
          />
        </Grid>
        <Grid item xs={1} key="boring_speed_input">
          <ParamField
            disable={!props.enableEdit}
            name="Boring speed"
            changeValue={changeBoringSpeed}
            value={boringSpeed}
            currentData={ppCurrentData === null ? null : ppCurrentData.boring_down_speed}
          />
        </Grid>
        <Grid item xs={1} key="drilling_speed_input">
          <ParamField
            disable={!props.enableEdit}
            name="Drilling speed"
            changeValue={changeDrillingSpeed}
            value={drillingSpeed}
            currentData={ppCurrentData === null ? null : ppCurrentData.drilling_speed}
          />
        </Grid>
        <Grid item xs={1} key="current_speed_input">
          <ParamField
            disable={!props.enableEdit}
            name="Current speed"
            changeValue={changeCurrentSpeed}
            value={currentSpeed}
            precision={1}
            currentData={
              controllerCurrentData === null
                ? null
                : controllerCurrentData.current_speed
            }
          />
        </Grid>
        <Grid item xs={2} key="area_size">
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Area size
          </Typography>
        </Grid>
        <Grid item xs={1} key="area_width_input">
          <ParamField
            name="Area width"
            value={areaWidth}
            changeValue={changeAreaWidth}
            disable={!props.enableEdit}
            currentData={
              controllerCurrentData === null
                ? null
                : controllerCurrentData.area_width
            }
          />
        </Grid>
        <Grid item xs={1} key="area_height_input">
          <ParamField
            name="Area height"
            value={areaHeight}
            changeValue={changeAreaHeight}
            disable={!props.enableEdit}
            currentData={
              controllerCurrentData === null
                ? null
                : controllerCurrentData.area_height
            }
          />
        </Grid>
        <Grid item xs={1} key="start_layer_input">
          <ParamField
            name="Start layer"
            value={startLayer}
            changeValue={changeStartLayer}
            disable={!props.enableEdit}
            currentData={
              ppCurrentData === null
                ? null
                : ppCurrentData.start_layer
            }
          />
        </Grid>
        <Grid item xs={1} key="end_layer_input">
          <ParamField
            name="End layer"
            value={endLayer}
            changeValue={changeEndLayer}
            disable={!props.enableEdit}
            currentData={
              ppCurrentData === null ? null : ppCurrentData.end_layer
            }
          />
        </Grid>
        <Grid item xs={2} key="other">
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Other
          </Typography>
        </Grid>
        <Grid item xs={1} key="mill_to_cam_x_input">
          <ParamField
            name="Mill to camera X"
            disable={!props.enableEdit}
            changeValue={(e: number) => {
              if (millToCamera !== null) {
                changeMillToCamera([e, millToCamera[1]]);
              }
            }}
            value={millToCamera == null ? null : millToCamera[0]}
            precision={1}
              helperText=" (mm, - left, + right)"
            currentData={tfCurrentData === null ? null : tfCurrentData.mill_to_camera_center[0]}
            negativeEnabled={true}
          />
        </Grid>
        <Grid item xs={1} key="mill_to_cam_y_input">
          <ParamField
            name="Mill to camera Y"
            disable={!props.enableEdit}
            changeValue={(e: number) => {
              if (millToCamera !== null) {
                changeMillToCamera([millToCamera[0], e]);
              }
            }}
            value={millToCamera == null ? null : millToCamera[1]}
            precision={1}
            helperText=" (mm, + up, - down)"
            currentData={tfCurrentData === null ? null : tfCurrentData.mill_to_camera_center[1]}
            negativeEnabled={true}
          />
        </Grid> 
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={severity !== null && message !== null}
        onClose={() => { setSeverity(null); setMessage(null); }}
        autoHideDuration={4000}
      >
        <Alert onClose={() => { setSeverity(null); setMessage(null); }} severity={(severity || "info") as any} variant="filled">
          {message}
        </Alert>
      </Snackbar>
      </Stack>
    </TabPanel>
  );
}