import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyA6YoxwepmuwWHqhDlqMmc7k-8hvctOsGk",
    authDomain: "sitetech-69d23.firebaseapp.com",
    projectId: "sitetech-69d23",
    storageBucket: "sitetech-69d23.appspot.com",
    messagingSenderId: "532178762836",
    appId: "1:532178762836:web:645d4636d2e5c31abc41c1",
    measurementId: "G-RRBBR08B1Z"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {app, db, auth}