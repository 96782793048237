import * as React from "react";
import * as ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './index.css';
import LoginPage from "./pages/LoginPage"
import KPIPage from "./pages/KPIPage"
import DataPage from "./pages/DataPage"
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import RobotsPage from "./pages/RobotsPage2";
import MLPage from "./pages/MLPage";

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#BC4A3C',
        },
        secondary: {
            main: '#adbc3c',
        },
    },
});

function App() {
    const appName = "SiteTech console";
    const backendUrl = "https://api.site-tech.dk";


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ThemeProvider theme={theme}> <CssBaseline/> <LoginPage
                    redirect="/data" name={appName}/></ThemeProvider>}/>
                <Route path="/kpi" element={<ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <KPIPage name={appName} apiUrl={backendUrl}/>
                </ThemeProvider>}/>
                <Route path="/data" element={<ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <DataPage name={appName} apiUrl={backendUrl}/>
                </ThemeProvider>}/>
                <Route path="/robots/:robotName?" element={<ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <RobotsPage name={appName} apiUrl={backendUrl}/>
                </ThemeProvider>}/>
                <Route path="/ml" element={<ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <MLPage name={appName} apiUrl={backendUrl}/>
                </ThemeProvider>}/>
            </Routes>
        </BrowserRouter>
    );
}

// ========================================
export {theme};
const rootDiv = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootDiv);
root.render(<App/>);

