import React, {useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {PopoverProps} from '@mui/material/Popover';
import {styled} from "@mui/material/styles";

interface SubMenuProps {
    anchorPosition?: PopoverProps['anchorPosition'];
    text: string,
    options: Array<string>,
    selectFunction: (option: string) => void
}

const StyledMenu = styled(Menu)({
    maxHeight: '200px',
    '& .MuiPaper-root': {
        overflowY: 'auto',
        maxHeight: '100%',
    },
});

export default function SubMenu(props: SubMenuProps) {
    const {anchorPosition} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    function handleOpenMenu(event: React.MouseEvent<HTMLLIElement>) {
        setAnchorEl(event.currentTarget);
        setIsSubMenuOpen(true);

    }

    function handleCloseMenu() {
        setAnchorEl(null);
        setIsSubMenuOpen(false);
    }

    function handleCloseMenuMouseLeave() {
        setTimeout(() => {
            if (
                !anchorEl ||
                (!anchorEl.contains(document.activeElement) && !isSubMenuOpen)
            ) {
                handleCloseMenu();
            }
        }, 0);
    }

    function handleSubMenuMouseEnter() {
        setIsSubMenuOpen(true);
    }

    function handleSubMenuMouseLeave() {
        setIsSubMenuOpen(false);
        handleCloseMenu();
    }

    return (
        <>
            <MenuItem
                onMouseEnter={handleOpenMenu}
                onMouseLeave={handleCloseMenuMouseLeave}
            >
                {props.text}
            </MenuItem>
            <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorReference={!!anchorPosition ? 'anchorPosition' : 'anchorEl'}
                anchorPosition={anchorPosition}
                disablePortal
                onMouseEnter={handleSubMenuMouseEnter}
                onMouseLeave={handleSubMenuMouseLeave}
            >
                {props.options.map((option: string) => <MenuItem key={option}
                                                                 onClick={() => props.selectFunction(option)}>{option}</MenuItem>)}
            </StyledMenu>
        </>
    );
}