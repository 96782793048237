import {Button} from "@mui/material";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import {MLProgress} from "./TrainingTab";
import {useInterval} from "../../utils/utils";
import React from "react";


export default function RunTrainingButton(props: { apiUrl: string, startTraining: () => void }) {
    const [status, setStatus] = React.useState<string | null>(null);

    useInterval(() => {
        fetch(props.apiUrl + "/ml/train/progress", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (!response.ok) {
                setStatus(null);
                return null;
            }
            return response.json();
        }).then((json: MLProgress) => {
            if (json === null) {
                setStatus(null);
            } else {
                setStatus(json.status);
            }
        });
    }, 1000);

    return (
        <Button
            variant="contained"
            color="primary"
            endIcon={(status !== null && status !== "done") ? <CloudSyncIcon/> : <ModelTrainingIcon/>}
            sx={{width: "100%", height: "59px"}}
            onClick={props.startTraining}
            disabled={(status !== null && status !== "done")}
        >
            {(status !== null && status !== "done") ? "Training in progress" : "Run training"}
        </Button>);
}